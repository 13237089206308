import React from "react";
import { Redirect } from "react-router";
import { Link, useLocation } from "react-router-dom";

type TGraphErrorPage = "business" | "influencer" | "admin" | "super" | "main";
interface IProps {
  err: any;
  page: TGraphErrorPage;
}

const err_login_false = "Access denied! You don't have permission for this action!";
const err_login_denied = "Cannot read property 'token' of null";

const GraphError: React.FC<IProps> = ({ err, page }) => {
  console.log("graph error");
  console.log(err);

  const location = useLocation();

  const handleRefreshPage = () => {
    window.location.reload();
  };

  // 로그인 되어 있지 않았을 때 Redirect

  if (err === err_login_false) {
    if (page === "business") {
      return <Redirect to="/login/store" />;
    }
    if (page === "influencer") {
      return <Redirect to="/login/influencer" />;
    }
    if (page === "admin") {
      return <Redirect to="/login/admin" />;
    } else {
      console.log("Login Page - Not in [ admin, influencer, busienss ]");
    }
  }

  if (err === err_login_denied) {
    return (
      <div className="error">
        <div className="error__wrap">
          <h1 className="error__title">로그인 실패</h1>
          <div className="error__btnwrap" style={{ color: "black" }}>
            로그인 정보가 일치하지 않습니다.
          </div>
          <button className="error__btn" onClick={handleRefreshPage}>
            다시시도
          </button>
        </div>
      </div>
    );
  }

  console.log({ err });

  return (
    <div className="error">
      <div className="error__wrap">
        <h1 className="error__title">데이터 연결 에러</h1>
        <div className="error__btnwrap" style={{ color: "black" }}>
          {err}
        </div>
        <div className="error__btnwrap">
          {location.pathname !== "/influencer/sales" && (
            <Link to="/" className="error__btn">
              돌아가기
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default GraphError;
