import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import PageLoading from "component/common/loading/PageLoading";
import Main from "page/common/Main";
import Store from "page/store/Store";
import Influencer from "page/influencer/Influencer";
import Admin from "page/admin/Admin";
import Super from "page/super/Super";
import { isProduction } from "./utils/utils";

/* ::::::: 공통 ::::::: */
const LOGINSELECT = React.lazy(() => import("page/common/LoginSelect"));
const NOTFOUND = React.lazy(() => import("page/common/NotFound"));

/* ::::::: 소상공인 ::::::: */
const STORE_LOGIN = React.lazy(() => import("page/store/Login"));
const STORE_REGISTER = React.lazy(() => import("page/store/Register"));
const STORE_STORELIST = React.lazy(() => import("page/store/storelist/StoreList"));
const STORE_STOREINFLUENCER = React.lazy(() => import("page/store/storeInfluencer/StoreInfluencer"));
const STORE_CART = React.lazy(() => import("page/store/cart/Cart"));
const STORE_RECORDS = React.lazy(() => import("page/store/records/Records"));

/* ::::::: 인플루언서 ::::::: */
const INFLUENCER_LOGIN = React.lazy(() => import("page/influencer/Login"));
const INFLUENCER_REGISTER = React.lazy(() => import("page/influencer/Register"));
const INFLUENCER_REQUEST = React.lazy(() => import("page/influencer/request/Request"));
const INFLUENCER_REJECT = React.lazy(() => import("page/influencer/reject/Reject"));
const INFLUENCER_PROFILE = React.lazy(() => import("page/influencer/profile/Profile"));
const INFLUENCER_SCHEDULE = React.lazy(() => import("page/influencer/schedule/Schedule"));
const INFLUENCER_DAYHOURS = React.lazy(() => import("page/influencer/dayhours/DayHours"));
const INFLUENCER_NOTICEAPPLY = React.lazy(() => import("page/influencer/NoticeApply"));
const INFLUENCER_REVENUE = React.lazy(() => import("page/influencer/renevue/Revenue"));
const INFLUENCER_SALES = React.lazy(() => import("page/influencer/sales/Sales"));

/* ::::::: 관리자 ::::::: */
const ADMIN_ORDERS = React.lazy(() => import("page/admin/orders/Orders"));
const ADMIN_REFUNDS = React.lazy(() => import("page/admin/refunds/Refunds"));
const ADMIN_REJECTREQUEST = React.lazy(() => import("page/admin/rejectrequest/RejectRequest"));
const ADMIN_REJECTHISTORY = React.lazy(() => import("page/admin/rejecthistory/RejectHistory"));
const ADMIN_STORELIST = React.lazy(() => import("page/admin/storelist/AdminStoreList"));
const ADMIN_INFLUENCER = React.lazy(() => import("page/admin/admininfluencer/AdminInfluencer"));
const ADMIN_SPECIALIST = React.lazy(() => import("page/admin/speciallist/SpecialList"));
const ADMIN_CANDIDATES = React.lazy(() => import("page/admin/candidate/Candidates"));
const ADMIN_CATEGORY = React.lazy(() => import("page/admin/category/Category"));
const ADMIN_SMS = React.lazy(() => import("page/admin/sms/SMS"));
const ADMIN_LOGIN = React.lazy(() => import("page/admin/Login"));
const ADMIN_REVENUE = React.lazy(() => import("page/admin/revenue/Revenue"));
const ADMIN_FREESETTING = React.lazy(() => import("page/admin/settingfees/FeeSetting"));

/* ::::::: 최고 관리자 ::::::: */
const SUPER_LOGIN = React.lazy(() => import("page/super/Login"));
const SUPER_SETTING = React.lazy(() => import("page/super/Setting"));

console.log("Current Environtment::", isProduction() ? "production" : "development");

function App() {
  return (
    <>
      <div id="influencer_market">
        <Suspense fallback={<PageLoading text="Page Loading ..." />}>
          <Switch>
            {/* :::::::::: 

                    Router - 인플루언서 

                    :::::::::: */}
            <Route path="/login/influencer" exact render={() => <INFLUENCER_LOGIN />} />
            <Route path="/register/influencer" exact render={() => <INFLUENCER_REGISTER />} />
            <Route
              path="/influencer/request"
              exact
              render={() => (
                <Influencer>
                  <INFLUENCER_REQUEST />
                </Influencer>
              )}
            />
            <Route
              path="/influencer/reject"
              exact
              render={() => (
                <Influencer>
                  <INFLUENCER_REJECT />
                </Influencer>
              )}
            />
            <Route
              path="/influencer/profile"
              exact
              render={() => (
                <Influencer>
                  <INFLUENCER_PROFILE />
                </Influencer>
              )}
            />
            <Route
              path="/influencer/schedule"
              exact
              render={() => (
                <Influencer>
                  <INFLUENCER_SCHEDULE />
                </Influencer>
              )}
            />
            <Route
              path="/influencer/dayhours"
              exact
              render={() => (
                <Influencer>
                  <INFLUENCER_DAYHOURS />
                </Influencer>
              )}
            />
            <Route
              path="/influencer/revenue"
              exact
              render={() => (
                <Influencer>
                  <INFLUENCER_REVENUE />
                </Influencer>
              )}
            />
            <Route
              path="/influencer/sales"
              exact
              render={() => (
                <Influencer>
                  <INFLUENCER_SALES />
                </Influencer>
              )}
            />
            <Route path="/influencer/noticeapply" exact render={() => <INFLUENCER_NOTICEAPPLY />} />

            {/* :::::::::: 

                    Router - 상점 

                    :::::::::: */}
            <Route path="/login/store" exact render={(routeInfo) => <STORE_LOGIN routeInfo={routeInfo} />} />
            {/* <Route
                        path="/register/store"
                        exact
                        render={() => <STORE_REGISTER />}
                    /> */}
            <Route
              path="/store/storelist"
              exact
              render={() => (
                <Store>
                  <STORE_STORELIST />
                </Store>
              )}
            />
            <Route
              path="/store/influencerlist"
              exact
              render={() => (
                <Store>
                  <STORE_STOREINFLUENCER />
                </Store>
              )}
            />
            <Route
              path="/store/cart"
              exact
              render={() => (
                <Store>
                  <STORE_CART />
                </Store>
              )}
            />
            <Route
              path="/store/records"
              exact
              render={() => (
                <Store>
                  <STORE_RECORDS />
                </Store>
              )}
            />

            {/* :::::::::: 

                    Router - 관리자 

                    :::::::::: */}
            <Route path="/login/admin" exact render={() => <ADMIN_LOGIN />} />
            <Route
              path="/admin/orders"
              exact
              render={() => (
                <Admin>
                  <ADMIN_ORDERS />
                </Admin>
              )}
            />
            <Route
              path="/admin/refunds"
              exact
              render={() => (
                <Admin>
                  <ADMIN_REFUNDS />
                </Admin>
              )}
            />
            <Route
              path="/admin/reject_request"
              exact
              render={() => (
                <Admin>
                  <ADMIN_REJECTREQUEST />
                </Admin>
              )}
            />
            <Route
              path="/admin/reject_history"
              exact
              render={() => (
                <Admin>
                  <ADMIN_REJECTHISTORY />
                </Admin>
              )}
            />
            <Route
              path="/admin/storelist"
              exact
              render={() => (
                <Admin>
                  <ADMIN_STORELIST />
                </Admin>
              )}
            />
            <Route
              path="/admin/influencer"
              exact
              render={() => (
                <Admin>
                  <ADMIN_INFLUENCER />
                </Admin>
              )}
            />
            <Route
              path="/admin/speciallist"
              exact
              render={() => (
                <Admin>
                  <ADMIN_SPECIALIST />
                </Admin>
              )}
            />
            <Route
              path="/admin/candidates"
              exact
              render={() => (
                <Admin>
                  <ADMIN_CANDIDATES />
                </Admin>
              )}
            />
            <Route
              path="/admin/category"
              exact
              render={() => (
                <Admin>
                  <ADMIN_CATEGORY />
                </Admin>
              )}
            />
            <Route
              path="/admin/sms"
              exact
              render={() => (
                <Admin>
                  <ADMIN_SMS />
                </Admin>
              )}
            />
            <Route
              path="/admin/revenue"
              exact
              render={() => (
                <Admin>
                  <ADMIN_REVENUE />
                </Admin>
              )}
            />
            <Route
              path="/admin/feesetting"
              exact
              render={() => (
                <Admin>
                  <ADMIN_FREESETTING />
                </Admin>
              )}
            />

            {/* :::::::::: 

                    Router - 최고 관리자 

                    :::::::::: */}
            <Route path="/login/super" exact render={() => <SUPER_LOGIN />} />

            <Route
              path="/super/setting"
              exact
              render={() => (
                <Super>
                  <SUPER_SETTING />
                </Super>
              )}
            />

            {/* :::::::::: 

                    Router - 공통 

                    :::::::::: */}
            <Route path="/" exact render={() => <Main />} />

            <Route path="/login/select" exact render={() => <LOGINSELECT />} />

            <Route path="*" render={(routeProps) => <NOTFOUND routeProps={routeProps} />} />
          </Switch>
        </Suspense>
      </div>
    </>
  );
}

export default App;
