import "core-js/stable";
import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import client from "apollo/apolloClient";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import GA4React, { useGA4React } from "ga-4-react";
import "styles/normalize.css";
import "styles/common.scss";
import "styles/common/loading.scss";
import "styles/auth/auth.scss";

const ga4react = new GA4React("G-8VD998PYPM");

(async () => {
  await ga4react.initialize();

  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
  );
})();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
