import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Q_SUPER_PageInfoList, _PageInfoSort } from "apollo/types";
import { SUPER_EXTRAINFO } from "apollo/gql/query";
import "styles/influencer/influencer.scss";
import Sidebar from "component/common/sidebar/Sidebar";
import { ModalContext, CommonValueContext, ContextExtraData } from "context/ContextInfluencer";
import GraphError from "component/common/error/GraphError";
import { ExtraDataProcess } from "utils/utils";
import { TextraDataValue } from "type/super/superTypes";
import { token_role } from "utils/commonVariables";
import { TToken_Role } from "type/types";
import RoleAuthError from "component/common/error/RoleAuthError";

const Influencer: React.FC = ({ children }: any) => {
  const [commonValue, setCommonValue] = useState<any>({
    role: "influencer",
    isLogged: true,
  });

  const [modalState, setModalState] = useState({
    modal: "",
    active: false,
  });

  /* ::::: API CALL - 여분필드 리스트 ::::: */
  const {
    loading: exraLoading,
    error: extraError,
    data: extraData,
  } = useQuery<Q_SUPER_PageInfoList>(SUPER_EXTRAINFO, {
    variables: {
      sort: [_PageInfoSort.createdAt_desc],
      pageInput: {
        page: 1,
        cntPerPage: 10,
      },
    },
    fetchPolicy: "network-only",
  });

  const menuList = [
    { name: "요청리스트", link: "/influencer/request" },
    { name: "거절요청리스트", link: "/influencer/reject" },
    { name: "스케줄관리", link: "/influencer/schedule" },
    { name: "요일별시간", link: "/influencer/dayhours" },
    { name: "프로필관리", link: "/influencer/profile" },
    { name: "정산페이지", link: "/influencer/revenue" },
    { name: "판매량페이지", link: "/influencer/sales" },
  ];

  if (localStorage.getItem(token_role) !== TToken_Role.influencer) {
    return <RoleAuthError role={localStorage.getItem(token_role)!} />;
  }
  if (exraLoading) return null;
  if (extraError) return <GraphError err={extraError.message} page="influencer" />;
  const extraDataValue: TextraDataValue = ExtraDataProcess(extraData?.PageInfoList.data);

  // console.log('login check !!!!!');
  // console.log(commonValue);

  return (
    <CommonValueContext.Provider value={{ commonValue, setCommonValue }}>
      <ModalContext.Provider value={{ modalState, setModalState }}>
        <ContextExtraData.Provider value={{ extraDataValue }}>
          <div className={`influencer`}>
            <div className={`bgfilter ${modalState.active && "on"}`}></div>
            <Sidebar title={"인플루언서"} menuList={menuList} extraDataValue={extraDataValue} />
            {children}
          </div>
        </ContextExtraData.Provider>
      </ModalContext.Provider>
    </CommonValueContext.Provider>
  );
};

export default Influencer;
