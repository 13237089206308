import React, { useEffect, useState } from "react";
import "styles/store/store.scss";
import Sidebar from "component/common/sidebar/Sidebar";
import { CommonValueContext, ContextExtraData, ModalContext } from "context/ContextInfluencer";
import { useQuery } from "@apollo/client";
import { Q_SUPER_PageInfoList, _PageInfoSort } from "apollo/types";
import { SUPER_EXTRAINFO } from "apollo/gql/query";
import GraphError from "component/common/error/GraphError";
import { ExtraDataProcess } from "utils/utils";
import { TextraDataValue } from "type/super/superTypes";
import RoleAuthError from "component/common/error/RoleAuthError";
import { token_role } from "utils/commonVariables";
import { TToken_Role } from "type/types";

const Store: React.FC = ({ children }: any) => {
  const [commonValue, setCommonValue] = useState<any>({
    role: "",
    isLogged: false,
  });

  const [modalState, setModalState] = useState({
    modal: "",
    active: false,
  });

  /* ::::: API CALL - 여분필드 리스트 ::::: */
  const {
    loading: exraLoading,
    error: extraError,
    data: extraData,
  } = useQuery<Q_SUPER_PageInfoList>(SUPER_EXTRAINFO, {
    variables: {
      sort: [_PageInfoSort.createdAt_desc],
      pageInput: {
        page: 1,
        cntPerPage: 10,
      },
    },
    fetchPolicy: "network-only",
  });

  const menuList = [
    { name: "상점리스트", link: "/store/storelist" },
    { name: "인플루언서서치", link: "/store/influencerlist" },
    { name: "장바구니", link: "/store/cart" },
    { name: "구입내역", link: "/store/records" },
  ];

  if (localStorage.getItem(token_role) !== TToken_Role.business) {
    return <RoleAuthError role={localStorage.getItem(token_role)!} />;
  }
  if (exraLoading) return null;
  if (extraError) return <GraphError err={extraError.message} page="business" />;

  const extraDataValue: TextraDataValue = ExtraDataProcess(extraData?.PageInfoList.data);

  // console.log('store common !!!!!');
  // console.log(commonValue);

  console.log("Store !!!!!");

  return (
    <CommonValueContext.Provider value={{ commonValue, setCommonValue }}>
      <ModalContext.Provider value={{ modalState, setModalState }}>
        <ContextExtraData.Provider value={{ extraDataValue }}>
          <div className={`store`}>
            <div className={`bgfilter ${modalState.active && "on"}`}></div>
            <Sidebar title={"스토어"} menuList={menuList} extraDataValue={extraDataValue} />
            {children}
          </div>
        </ContextExtraData.Provider>
      </ModalContext.Provider>
    </CommonValueContext.Provider>
  );
};

export default Store;
