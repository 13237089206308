import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { useQuery } from "@apollo/client";
import { CheckLoggedIn } from "apollo/types";
import { CHK_LOGGEDIN } from "apollo/gql/query";
import LoginChkLoading from "component/common/loading/LoginChkLoading";
import GraphError from "component/common/error/GraphError";
import RoleAuthError from "component/common/error/RoleAuthError";

const Main = () => {
  // const { loading, error, data } = useQuery<CheckLoggedIn>(
  //     CHK_LOGGEDIN,
  //     {
  //         fetchPolicy: 'network-only'
  //     }
  // );

  // const [commonValue, setCommonValue] = useState({
  //     role:'',
  //     isLogged:false
  // });

  // useEffect(()=>{
  //     if(data !== undefined) {
  //         setCommonValue({
  //             role:data!.CheckLoggedIn.data!.role,
  //             isLogged:data!.CheckLoggedIn.data!.isLoggedIn
  //         })
  //     }
  // },[data])

  // if (loading) return <LoginChkLoading />
  // if (error) return <GraphError err={error.message} page={'main'} />

  // const {isLogged, role} = commonValue;

  // console.log('main page');
  // console.log(data);

  return (
    <>
      <Redirect to="/login/select" />
    </>
  );
};

export default Main;
