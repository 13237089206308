import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, concat, from } from "@apollo/client";
import { onError } from "apollo-link-error";
import { createUploadLink } from "apollo-upload-client";
import dotenv from "dotenv";
import omitDeep from "omit-deep-lodash";
import { token_name } from "utils/commonVariables";
import { isProduction } from "../utils/utils";
dotenv.config({
  path: "../../env",
});

export const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, "__typename");
  }
  return forward(operation).map((data) => {
    return data;
  });
});

const httpLink: any = createUploadLink({
  uri: isProduction() ? `${process.env.REACT_APP_SERVER_URI}` : `${process.env.REACT_APP_LOCAL_SERVER_URI}`,
});

export const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: localStorage.getItem(token_name) || null,
    },
  });
  return forward(operation);
});

export const errorLink: any = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      const { message, locations, extensions } = error;
      console.error("======GRAPGQL error======");
      console.log("locations::", { locations });
      console.log("extensions::", { extensions });
      console.error("messages::", { message });
    });
  }
  if (networkError) {
    console.error("networkError", networkError);
  }
});

const client = new ApolloClient({
  link: from([cleanTypeName, authMiddleware, errorLink, httpLink]),
  cache: new InMemoryCache(),
});

export default client;
