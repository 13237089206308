/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckLoggedIn
// ====================================================

export interface CheckLoggedIn_CheckLoggedIn_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface CheckLoggedIn_CheckLoggedIn_data {
  __typename: "CheckLoggedIn";
  isLoggedIn: boolean;
  role: string;
}

export interface CheckLoggedIn_CheckLoggedIn {
  __typename: "CheckLoggedInResponse";
  ok: boolean;
  error: CheckLoggedIn_CheckLoggedIn_error | null;
  data: CheckLoggedIn_CheckLoggedIn_data | null;
}

export interface CheckLoggedIn {
  CheckLoggedIn: CheckLoggedIn_CheckLoggedIn;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CategoryList
// ====================================================

export interface CategoryList_CategoryList_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface CategoryList_CategoryList_data {
  __typename: "Category";
  _id: string;
  /**
   * 이름
   */
  name: string;
}

export interface CategoryList_CategoryList {
  __typename: "CategoryListResponse";
  ok: boolean;
  error: CategoryList_CategoryList_error | null;
  data: CategoryList_CategoryList_data[];
}

export interface CategoryList {
  CategoryList: CategoryList_CategoryList;
}

export interface CategoryListVariables {
  sort?: _CategorySort[] | null;
  filter?: _CategoryFilter | null;
  pageInput: pageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Q_PriceList
// ====================================================

export interface Q_PriceList_PriceList_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface Q_PriceList_PriceList_page {
  __typename: "Page";
  /**
   * 현재 페이지 번호
   */
  page: number;
  /**
   * 페이지당 문서 갯수
   */
  cntPerPage: number;
  /**
   * 페이지 총 갯수
   */
  totalPageSize: number;
  /**
   * 총 갯수
   */
  totalCount: number;
}

export interface Q_PriceList_PriceList_data {
  __typename: "Price";
  _id: string;
  /**
   * 인플루언서 가격
   */
  price: number;
}

export interface Q_PriceList_PriceList {
  __typename: "PriceListResponse";
  ok: boolean;
  error: Q_PriceList_PriceList_error | null;
  page: Q_PriceList_PriceList_page;
  data: Q_PriceList_PriceList_data[];
}

export interface Q_PriceList {
  PriceList: Q_PriceList_PriceList;
}

export interface Q_PriceListVariables {
  sort?: _PriceSort[] | null;
  filter?: _PriceFilter | null;
  pageInput: pageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InfluencerCodeFind
// ====================================================

export interface InfluencerCodeFind_InfluencerCodeFind_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface InfluencerCodeFind_InfluencerCodeFind {
  __typename: "InfluencerCodeResponse";
  ok: boolean;
  data: string | null;
  error: InfluencerCodeFind_InfluencerCodeFind_error | null;
}

export interface InfluencerCodeFind {
  InfluencerCodeFind: InfluencerCodeFind_InfluencerCodeFind;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Q_Inf_BookingList
// ====================================================

export interface Q_Inf_BookingList_BookingList_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface Q_Inf_BookingList_BookingList_page {
  __typename: "Page";
  /**
   * 현재 페이지 번호
   */
  page: number;
  /**
   * 페이지당 문서 갯수
   */
  cntPerPage: number;
  /**
   * 페이지 총 갯수
   */
  totalPageSize: number;
  /**
   * 총 갯수
   */
  totalCount: number;
}

export interface Q_Inf_BookingList_BookingList_data_latestRequestHistory {
  __typename: "RequestHistory";
  reqType: RequestStatus;
  reason: string;
}

export interface Q_Inf_BookingList_BookingList_data {
  __typename: "Booking";
  _id: string;
  /**
   * 상태
   */
  status: BookingStatus;
  /**
   * 소상공인 이름
   */
  bookerName: string;
  /**
   * 소상공인 닉네임
   */
  bookerNickName: string;
  /**
   * 예약 날짜
   */
  bookingDate: any;
  /**
   * 소상공인 핸드폰번호
   */
  bookerMemo: string;
  /**
   * 상점명
   */
  storeName: string;
  /**
   * 상점 주소
   */
  storeAddress: string;
  /**
   * 상점 소개글
   */
  storeIntroduce: string;
  /**
   * 네이버 스마트 플레이스 url
   */
  storeNaverSmartPlaceUrl: string | null;
  latestRequestHistory: Q_Inf_BookingList_BookingList_data_latestRequestHistory | null;
}

export interface Q_Inf_BookingList_BookingList {
  __typename: "BookingListResponse";
  ok: boolean;
  error: Q_Inf_BookingList_BookingList_error | null;
  page: Q_Inf_BookingList_BookingList_page;
  data: Q_Inf_BookingList_BookingList_data[];
}

export interface Q_Inf_BookingList {
  BookingList: Q_Inf_BookingList_BookingList;
}

export interface Q_Inf_BookingListVariables {
  sort?: _BookingSort[] | null;
  filter?: _BookingFilter | null;
  pageInput: pageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GraphSignIn
// ====================================================

export interface GraphSignIn_SignIn_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface GraphSignIn_SignIn_data {
  __typename: "SignIn";
  token: string;
}

export interface GraphSignIn_SignIn {
  __typename: "SiginResponse";
  ok: boolean;
  error: GraphSignIn_SignIn_error | null;
  data: GraphSignIn_SignIn_data | null;
}

export interface GraphSignIn {
  SignIn: GraphSignIn_SignIn;
}

export interface GraphSignInVariables {
  email: string;
  pw: string;
  hopeRole: UserRole;
  permanence?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ScheduleFindByMe
// ====================================================

export interface ScheduleFindByMe_ScheduleFindByMe_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface ScheduleFindByMe_ScheduleFindByMe_data_influencer {
  __typename: "User";
  _id: string;
}

export interface ScheduleFindByMe_ScheduleFindByMe_data_monday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface ScheduleFindByMe_ScheduleFindByMe_data_tuesday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface ScheduleFindByMe_ScheduleFindByMe_data_wednesday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface ScheduleFindByMe_ScheduleFindByMe_data_thursday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface ScheduleFindByMe_ScheduleFindByMe_data_friday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface ScheduleFindByMe_ScheduleFindByMe_data_saturday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface ScheduleFindByMe_ScheduleFindByMe_data_sunday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface ScheduleFindByMe_ScheduleFindByMe_data {
  __typename: "Schedule";
  _id: string;
  /**
   * 요일막기, 무슨 요일에 장사안하는지 ex) [0, 3, 4]
   */
  day: number[];
  /**
   * 인플루언서가 장사안하는 날짜목록 ex) ['2020-10-10', '2020=10-11']
   */
  self: any[];
  /**
   * 소상공인이 예약한 날짜목록 ex) ['2020-10-10', '2020=10-11']
   */
  book: any[];
  influencer: ScheduleFindByMe_ScheduleFindByMe_data_influencer;
  /**
   * 월요일 시작 ~ 종료 시간
   */
  monday: ScheduleFindByMe_ScheduleFindByMe_data_monday;
  /**
   * 화요일 시작 ~ 종료 시간
   */
  tuesday: ScheduleFindByMe_ScheduleFindByMe_data_tuesday;
  /**
   * 수요일 시작 ~ 종료 시간
   */
  wednesday: ScheduleFindByMe_ScheduleFindByMe_data_wednesday;
  /**
   * 목요일 시작 ~ 종료 시간
   */
  thursday: ScheduleFindByMe_ScheduleFindByMe_data_thursday;
  /**
   * 금요일 시작 ~ 종료 시간
   */
  friday: ScheduleFindByMe_ScheduleFindByMe_data_friday;
  /**
   * 토요일 시작 ~ 종료 시간
   */
  saturday: ScheduleFindByMe_ScheduleFindByMe_data_saturday;
  /**
   * 일요일 시작 ~ 종료 시간
   */
  sunday: ScheduleFindByMe_ScheduleFindByMe_data_sunday;
}

export interface ScheduleFindByMe_ScheduleFindByMe {
  __typename: "ScheduleFindByMeResponse";
  ok: boolean;
  error: ScheduleFindByMe_ScheduleFindByMe_error | null;
  data: ScheduleFindByMe_ScheduleFindByMe_data | null;
}

export interface ScheduleFindByMe {
  ScheduleFindByMe: ScheduleFindByMe_ScheduleFindByMe;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ScheduleFindByMeHours
// ====================================================

export interface ScheduleFindByMeHours_ScheduleFindByMe_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface ScheduleFindByMeHours_ScheduleFindByMe_data_influencer {
  __typename: "User";
  _id: string;
}

export interface ScheduleFindByMeHours_ScheduleFindByMe_data_monday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface ScheduleFindByMeHours_ScheduleFindByMe_data_tuesday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface ScheduleFindByMeHours_ScheduleFindByMe_data_wednesday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface ScheduleFindByMeHours_ScheduleFindByMe_data_thursday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface ScheduleFindByMeHours_ScheduleFindByMe_data_friday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface ScheduleFindByMeHours_ScheduleFindByMe_data_saturday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface ScheduleFindByMeHours_ScheduleFindByMe_data_sunday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface ScheduleFindByMeHours_ScheduleFindByMe_data {
  __typename: "Schedule";
  _id: string;
  /**
   * 요일막기, 무슨 요일에 장사안하는지 ex) [0, 3, 4]
   */
  day: number[];
  /**
   * 인플루언서가 장사안하는 날짜목록 ex) ['2020-10-10', '2020=10-11']
   */
  self: any[];
  /**
   * 소상공인이 예약한 날짜목록 ex) ['2020-10-10', '2020=10-11']
   */
  book: any[];
  influencer: ScheduleFindByMeHours_ScheduleFindByMe_data_influencer;
  /**
   * 월요일 시작 ~ 종료 시간
   */
  monday: ScheduleFindByMeHours_ScheduleFindByMe_data_monday;
  /**
   * 화요일 시작 ~ 종료 시간
   */
  tuesday: ScheduleFindByMeHours_ScheduleFindByMe_data_tuesday;
  /**
   * 수요일 시작 ~ 종료 시간
   */
  wednesday: ScheduleFindByMeHours_ScheduleFindByMe_data_wednesday;
  /**
   * 목요일 시작 ~ 종료 시간
   */
  thursday: ScheduleFindByMeHours_ScheduleFindByMe_data_thursday;
  /**
   * 금요일 시작 ~ 종료 시간
   */
  friday: ScheduleFindByMeHours_ScheduleFindByMe_data_friday;
  /**
   * 토요일 시작 ~ 종료 시간
   */
  saturday: ScheduleFindByMeHours_ScheduleFindByMe_data_saturday;
  /**
   * 일요일 시작 ~ 종료 시간
   */
  sunday: ScheduleFindByMeHours_ScheduleFindByMe_data_sunday;
}

export interface ScheduleFindByMeHours_ScheduleFindByMe {
  __typename: "ScheduleFindByMeResponse";
  ok: boolean;
  error: ScheduleFindByMeHours_ScheduleFindByMe_error | null;
  data: ScheduleFindByMeHours_ScheduleFindByMe_data | null;
}

export interface ScheduleFindByMeHours {
  ScheduleFindByMe: ScheduleFindByMeHours_ScheduleFindByMe;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProfileFindByMe
// ====================================================

export interface ProfileFindByMe_ProfileFindByMe_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface ProfileFindByMe_ProfileFindByMe_data_profileImage {
  __typename: "File";
  _id: string;
  name: string;
  description: string | null;
  extension: string | null;
  fileType: string | null;
  uri: string;
  owner: string;
  x: number | null;
  y: number | null;
}

export interface ProfileFindByMe_ProfileFindByMe_data_sns {
  __typename: "SNS";
  label: string;
  value: string;
}

export interface ProfileFindByMe_ProfileFindByMe_data_categories {
  __typename: "Category";
  _id: string;
  /**
   * 이름
   */
  name: string;
}

export interface ProfileFindByMe_ProfileFindByMe_data {
  __typename: "Profile";
  _id: string;
  /**
   * 프로필 공개 여부
   */
  isOpen: boolean;
  isDelete: boolean;
  /**
   * 이름
   */
  name: string;
  /**
   * 인플루언서 이메일, 검색을 위해 필요
   */
  influencerEmail: string;
  /**
   * 핸드폰번호
   */
  phoneNumber: string;
  /**
   * 싫어 하는 음식 리스트
   */
  unfavorList: string | null;
  /**
   * 프로필 이미지
   */
  profileImage: ProfileFindByMe_ProfileFindByMe_data_profileImage | null;
  /**
   * 본인 소개
   */
  introduce: string;
  /**
   * 활동 가능 지역
   */
  region: string;
  /**
   * sns url
   */
  sns: ProfileFindByMe_ProfileFindByMe_data_sns[];
  /**
   * 개인 홈페이지 url
   */
  homepageURL: string;
  /**
   * 카테고리
   */
  categories: ProfileFindByMe_ProfileFindByMe_data_categories[];
}

export interface ProfileFindByMe_ProfileFindByMe {
  __typename: "ProfileFindByMeResponse";
  ok: boolean;
  error: ProfileFindByMe_ProfileFindByMe_error | null;
  data: ProfileFindByMe_ProfileFindByMe_data | null;
}

export interface ProfileFindByMe {
  ProfileFindByMe: ProfileFindByMe_ProfileFindByMe;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Q_Influencer_Count
// ====================================================

export interface Q_Influencer_Count_Count_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface Q_Influencer_Count_Count_data {
  __typename: "Count";
  /**
   * 현재 요청수
   */
  nowBookCnt: number;
  /**
   * 누적 요청수
   */
  accBookCnt: number;
}

export interface Q_Influencer_Count_Count {
  __typename: "CountResponse";
  ok: boolean;
  error: Q_Influencer_Count_Count_error | null;
  data: Q_Influencer_Count_Count_data | null;
}

export interface Q_Influencer_Count {
  Count: Q_Influencer_Count_Count;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Q_Inf_AccountFindByMe
// ====================================================

export interface Q_Inf_AccountFindByMe_AccountFindByMe_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface Q_Inf_AccountFindByMe_AccountFindByMe_data {
  __typename: "Account";
  /**
   * 은행 ex) 국민, 우리, 부산
   */
  bankName: string | null;
  /**
   * 계좌 번호
   */
  accNumber: string | null;
  /**
   * 예금주명
   */
  bankOwnerName: string | null;
}

export interface Q_Inf_AccountFindByMe_AccountFindByMe {
  __typename: "AccountFindByMeResponse";
  ok: boolean;
  error: Q_Inf_AccountFindByMe_AccountFindByMe_error | null;
  data: Q_Inf_AccountFindByMe_AccountFindByMe_data | null;
}

export interface Q_Inf_AccountFindByMe {
  AccountFindByMe: Q_Inf_AccountFindByMe_AccountFindByMe;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Q_SettlementList
// ====================================================

export interface Q_SettlementList_SettlementList_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface Q_SettlementList_SettlementList_page {
  __typename: "Page";
  /**
   * 현재 페이지 번호
   */
  page: number;
  /**
   * 페이지당 문서 갯수
   */
  cntPerPage: number;
  /**
   * 페이지 총 갯수
   */
  totalPageSize: number;
  /**
   * 총 갯수
   */
  totalCount: number;
}

export interface Q_SettlementList_SettlementList_data_requestHistory {
  __typename: "RequestHistory";
  date: any;
  reqType: RequestStatus;
}

export interface Q_SettlementList_SettlementList_data_bookings {
  __typename: "Booking";
  _id: string;
  /**
   * 상태
   */
  status: BookingStatus;
  /**
   * 상점명
   */
  storeName: string;
  /**
   * 예약 날짜
   */
  bookingDate: any;
  /**
   * 가격
   */
  price: number;
}

export interface Q_SettlementList_SettlementList_data_feePolicy_addtionalFees {
  __typename: "AddtionalFees";
  feeName: string;
  feePercent: number;
  fee: number;
}

export interface Q_SettlementList_SettlementList_data_feePolicy {
  __typename: "FeePolicy";
  addtionalFees: Q_SettlementList_SettlementList_data_feePolicy_addtionalFees[];
}

export interface Q_SettlementList_SettlementList_data {
  __typename: "Settlement";
  status: SettlementStatus | null;
  requestHistory: Q_SettlementList_SettlementList_data_requestHistory[];
  bookings: Q_SettlementList_SettlementList_data_bookings[] | null;
  /**
   * 예약갯수
   */
  bookingsCnt: number;
  /**
   * 정산 시작 일자
   */
  startDate: any;
  /**
   * 정산 종료 일자
   */
  endDate: any;
  /**
   * 총 판매액
   */
  totalPrice: number | null;
  feePolicy: Q_SettlementList_SettlementList_data_feePolicy;
  /**
   * 수수료 총합
   */
  additionFeeSum: number | null;
  /**
   * 수수료 뺀 정산 금액
   */
  settlePrice: number | null;
}

export interface Q_SettlementList_SettlementList_supply {
  __typename: "SettlementListSupply";
  bookingPriceTotal: number;
  feeTotal: number;
  settlePriceTotal: number;
}

export interface Q_SettlementList_SettlementList {
  __typename: "SettlementListResponse";
  ok: boolean;
  error: Q_SettlementList_SettlementList_error | null;
  page: Q_SettlementList_SettlementList_page;
  data: Q_SettlementList_SettlementList_data[];
  supply: Q_SettlementList_SettlementList_supply;
}

export interface Q_SettlementList {
  SettlementList: Q_SettlementList_SettlementList;
}

export interface Q_SettlementListVariables {
  sort?: _SettlementSort[] | null;
  filter?: _SettlementFilter | null;
  pageInput: pageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Q_StoreList
// ====================================================

export interface Q_StoreList_StoreList_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface Q_StoreList_StoreList_page {
  __typename: "Page";
  /**
   * 현재 페이지 번호
   */
  page: number;
  /**
   * 페이지당 문서 갯수
   */
  cntPerPage: number;
  /**
   * 페이지 총 갯수
   */
  totalPageSize: number;
  /**
   * 총 갯수
   */
  totalCount: number;
}

export interface Q_StoreList_StoreList_data {
  __typename: "Store";
  _id: string;
  /**
   * 상점명
   */
  storeName: string;
  /**
   * 상점 주소
   */
  address: string;
  /**
   * 상점 연락처
   */
  phoneNumber: string;
  /**
   * 가게 ex) 식당, 도소매업
   */
  buisnessRange: string;
  /**
   * 상점 소개
   */
  introduce: string;
  /**
   * 네이버 스마트플레이스 url
   */
  naverSmartPlaceUrl: string | null;
}

export interface Q_StoreList_StoreList {
  __typename: "StoreListResponse";
  ok: boolean;
  error: Q_StoreList_StoreList_error | null;
  page: Q_StoreList_StoreList_page;
  data: Q_StoreList_StoreList_data[];
}

export interface Q_StoreList {
  StoreList: Q_StoreList_StoreList;
}

export interface Q_StoreListVariables {
  sort?: _StoreSort[] | null;
  filter?: _StoreFilter | null;
  pageInput: pageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Q_ProfileList
// ====================================================

export interface Q_ProfileList_ProfileList_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface Q_ProfileList_ProfileList_page {
  __typename: "Page";
  /**
   * 현재 페이지 번호
   */
  page: number;
  /**
   * 페이지당 문서 갯수
   */
  cntPerPage: number;
  /**
   * 페이지 총 갯수
   */
  totalPageSize: number;
  /**
   * 총 갯수
   */
  totalCount: number;
}

export interface Q_ProfileList_ProfileList_data_sns {
  __typename: "SNS";
  label: string;
  value: string;
}

export interface Q_ProfileList_ProfileList_data_categories {
  __typename: "Category";
  _id: string;
  /**
   * 이름
   */
  name: string;
}

export interface Q_ProfileList_ProfileList_data_profileImage {
  __typename: "File";
  uri: string;
  x: number | null;
  y: number | null;
}

export interface Q_ProfileList_ProfileList_data {
  __typename: "Profile";
  _id: string;
  /**
   * 프로필 공개 여부
   */
  isOpen: boolean;
  /**
   * 이름
   */
  name: string;
  /**
   * sns url
   */
  sns: Q_ProfileList_ProfileList_data_sns[];
  /**
   * 본인 소개
   */
  introduce: string;
  /**
   * 싫어 하는 음식 리스트
   */
  unfavorList: string | null;
  bookingTotalCnt: number;
  /**
   * 카테고리
   */
  categories: Q_ProfileList_ProfileList_data_categories[];
  price: number;
  /**
   * 프로필 이미지
   */
  profileImage: Q_ProfileList_ProfileList_data_profileImage | null;
}

export interface Q_ProfileList_ProfileList {
  __typename: "ProfileListResponse";
  ok: boolean;
  error: Q_ProfileList_ProfileList_error | null;
  page: Q_ProfileList_ProfileList_page;
  data: Q_ProfileList_ProfileList_data[];
}

export interface Q_ProfileList {
  ProfileList: Q_ProfileList_ProfileList;
}

export interface Q_ProfileListVariables {
  sort?: _ProfileSort[] | null;
  filter?: _ProfileFilter | null;
  pageInput: pageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Q_Store_SpecialList
// ====================================================

export interface Q_Store_SpecialList_RecommandProfileFindOne_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface Q_Store_SpecialList_RecommandProfileFindOne_data_profiles_profileImage {
  __typename: "File";
  uri: string;
  x: number | null;
  y: number | null;
}

export interface Q_Store_SpecialList_RecommandProfileFindOne_data_profiles_categories {
  __typename: "Category";
  _id: string;
  /**
   * 이름
   */
  name: string;
}

export interface Q_Store_SpecialList_RecommandProfileFindOne_data_profiles {
  __typename: "Profile";
  _id: string;
  /**
   * 이름
   */
  name: string;
  /**
   * 프로필 이미지
   */
  profileImage: Q_Store_SpecialList_RecommandProfileFindOne_data_profiles_profileImage | null;
  /**
   * 카테고리
   */
  categories: Q_Store_SpecialList_RecommandProfileFindOne_data_profiles_categories[];
  /**
   * 개인 홈페이지 url
   */
  homepageURL: string;
  /**
   * 인스타그램 url
   */
  instagramURL: string;
  /**
   * 본인 소개
   */
  introduce: string;
  /**
   * 프로필 공개 여부
   */
  isOpen: boolean;
  price: number;
  /**
   * 싫어 하는 음식 리스트
   */
  unfavorList: string | null;
  bookingTotalCnt: number;
}

export interface Q_Store_SpecialList_RecommandProfileFindOne_data {
  __typename: "RecommandProfile";
  _id: string;
  isDisplay: boolean | null;
  /**
   * 최소로 출력해야 하는 프로필 갯수, 최소 갯수 미만이면 리스트 출력 X
   */
  minCount: number | null;
  /**
   * 출력할수 있는 최대 프로필 갯수,
   */
  maxCount: number | null;
  /**
   * 카테고리
   */
  profiles: Q_Store_SpecialList_RecommandProfileFindOne_data_profiles[] | null;
}

export interface Q_Store_SpecialList_RecommandProfileFindOne {
  __typename: "RecommandProfileFindOneResponse";
  ok: boolean;
  error: Q_Store_SpecialList_RecommandProfileFindOne_error | null;
  data: Q_Store_SpecialList_RecommandProfileFindOne_data | null;
}

export interface Q_Store_SpecialList {
  RecommandProfileFindOne: Q_Store_SpecialList_RecommandProfileFindOne;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Q_ProfileBuyInfo
// ====================================================

export interface Q_ProfileBuyInfo_ProfileBuyInfo_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface Q_ProfileBuyInfo_ProfileBuyInfo_data_profile {
  __typename: "Profile";
  _id: string;
  price: number;
}

export interface Q_ProfileBuyInfo_ProfileBuyInfo_data_schedule_monday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface Q_ProfileBuyInfo_ProfileBuyInfo_data_schedule_tuesday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface Q_ProfileBuyInfo_ProfileBuyInfo_data_schedule_wednesday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface Q_ProfileBuyInfo_ProfileBuyInfo_data_schedule_thursday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface Q_ProfileBuyInfo_ProfileBuyInfo_data_schedule_friday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface Q_ProfileBuyInfo_ProfileBuyInfo_data_schedule_saturday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface Q_ProfileBuyInfo_ProfileBuyInfo_data_schedule_sunday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface Q_ProfileBuyInfo_ProfileBuyInfo_data_schedule {
  __typename: "Schedule";
  _id: string;
  /**
   * 요일막기, 무슨 요일에 장사안하는지 ex) [0, 3, 4]
   */
  day: number[];
  /**
   * 인플루언서가 장사안하는 날짜목록 ex) ['2020-10-10', '2020=10-11']
   */
  self: any[];
  /**
   * 소상공인이 예약한 날짜목록 ex) ['2020-10-10', '2020=10-11']
   */
  book: any[];
  /**
   * 월요일 시작 ~ 종료 시간
   */
  monday: Q_ProfileBuyInfo_ProfileBuyInfo_data_schedule_monday;
  /**
   * 화요일 시작 ~ 종료 시간
   */
  tuesday: Q_ProfileBuyInfo_ProfileBuyInfo_data_schedule_tuesday;
  /**
   * 수요일 시작 ~ 종료 시간
   */
  wednesday: Q_ProfileBuyInfo_ProfileBuyInfo_data_schedule_wednesday;
  /**
   * 목요일 시작 ~ 종료 시간
   */
  thursday: Q_ProfileBuyInfo_ProfileBuyInfo_data_schedule_thursday;
  /**
   * 금요일 시작 ~ 종료 시간
   */
  friday: Q_ProfileBuyInfo_ProfileBuyInfo_data_schedule_friday;
  /**
   * 토요일 시작 ~ 종료 시간
   */
  saturday: Q_ProfileBuyInfo_ProfileBuyInfo_data_schedule_saturday;
  /**
   * 일요일 시작 ~ 종료 시간
   */
  sunday: Q_ProfileBuyInfo_ProfileBuyInfo_data_schedule_sunday;
}

export interface Q_ProfileBuyInfo_ProfileBuyInfo_data {
  __typename: "ProfileBuyInfo";
  /**
   * 마스터용::가입된 가이드 인원수
   */
  profile: Q_ProfileBuyInfo_ProfileBuyInfo_data_profile;
  /**
   * 마스터용::가입된 가이드 인원수
   */
  schedule: Q_ProfileBuyInfo_ProfileBuyInfo_data_schedule;
}

export interface Q_ProfileBuyInfo_ProfileBuyInfo {
  __typename: "ProfileBuyInfoResponse";
  ok: boolean;
  error: Q_ProfileBuyInfo_ProfileBuyInfo_error | null;
  data: Q_ProfileBuyInfo_ProfileBuyInfo_data | null;
}

export interface Q_ProfileBuyInfo {
  ProfileBuyInfo: Q_ProfileBuyInfo_ProfileBuyInfo;
}

export interface Q_ProfileBuyInfoVariables {
  profileId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Q_Store_BasketItemList
// ====================================================

export interface Q_Store_BasketItemList_BasketItemList_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface Q_Store_BasketItemList_BasketItemList_page {
  __typename: "Page";
  /**
   * 현재 페이지 번호
   */
  page: number;
  /**
   * 페이지당 문서 갯수
   */
  cntPerPage: number;
  /**
   * 페이지 총 갯수
   */
  totalPageSize: number;
  /**
   * 총 갯수
   */
  totalCount: number;
}

export interface Q_Store_BasketItemList_BasketItemList_data_profile_categories {
  __typename: "Category";
  _id: string;
  /**
   * 이름
   */
  name: string;
}

export interface Q_Store_BasketItemList_BasketItemList_data_profile_profileImage {
  __typename: "File";
  _id: string;
  uri: string;
  x: number | null;
  y: number | null;
}

export interface Q_Store_BasketItemList_BasketItemList_data_profile {
  __typename: "Profile";
  /**
   * 이름
   */
  name: string;
  /**
   * 닉네임
   */
  nickName: string;
  bookingTotalCnt: number;
  /**
   * 카테고리
   */
  categories: Q_Store_BasketItemList_BasketItemList_data_profile_categories[];
  /**
   * 프로필 이미지
   */
  profileImage: Q_Store_BasketItemList_BasketItemList_data_profile_profileImage | null;
  /**
   * 인스타그램 url
   */
  instagramURL: string;
  /**
   * 개인 홈페이지 url
   */
  homepageURL: string;
  /**
   * 싫어 하는 음식 리스트
   */
  unfavorList: string | null;
  /**
   * 본인 소개
   */
  introduce: string;
  price: number;
}

export interface Q_Store_BasketItemList_BasketItemList_data {
  __typename: "BasketItem";
  _id: string;
  /**
   * 상점명
   */
  storeName: string;
  /**
   * 예약 날짜
   */
  bookingDate: any;
  /**
   * 예약 가능한지 여부
   */
  isBooking: boolean;
  /**
   * 프로필
   */
  profile: Q_Store_BasketItemList_BasketItemList_data_profile;
}

export interface Q_Store_BasketItemList_BasketItemList {
  __typename: "BasketItemListResponse";
  ok: boolean;
  error: Q_Store_BasketItemList_BasketItemList_error | null;
  page: Q_Store_BasketItemList_BasketItemList_page;
  data: Q_Store_BasketItemList_BasketItemList_data[];
}

export interface Q_Store_BasketItemList {
  BasketItemList: Q_Store_BasketItemList_BasketItemList;
}

export interface Q_Store_BasketItemListVariables {
  sort?: _BasketItemSort[] | null;
  filter?: _BasketItemFilter | null;
  pageInput: pageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Q_Store_BookingList
// ====================================================

export interface Q_Store_BookingList_BookingList_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface Q_Store_BookingList_BookingList_page {
  __typename: "Page";
  /**
   * 현재 페이지 번호
   */
  page: number;
  /**
   * 페이지당 문서 갯수
   */
  cntPerPage: number;
  /**
   * 총 갯수
   */
  totalCount: number;
}

export interface Q_Store_BookingList_BookingList_data_profile {
  __typename: "Profile";
  /**
   * 이름
   */
  name: string;
}

export interface Q_Store_BookingList_BookingList_data {
  __typename: "Booking";
  _id: string;
  createdAt: any;
  /**
   * 상점명
   */
  storeName: string;
  /**
   * 예약 날짜
   */
  bookingDate: any;
  /**
   * 가격
   */
  price: number;
  /**
   * 상태
   */
  status: BookingStatus;
  /**
   * 프로필
   */
  profile: Q_Store_BookingList_BookingList_data_profile;
}

export interface Q_Store_BookingList_BookingList {
  __typename: "BookingListResponse";
  ok: boolean;
  error: Q_Store_BookingList_BookingList_error | null;
  page: Q_Store_BookingList_BookingList_page;
  data: Q_Store_BookingList_BookingList_data[];
}

export interface Q_Store_BookingList {
  BookingList: Q_Store_BookingList_BookingList;
}

export interface Q_Store_BookingListVariables {
  sort?: _BookingSort[] | null;
  filter?: _BookingFilter | null;
  pageInput: pageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Q_Admin_BookingList
// ====================================================

export interface Q_Admin_BookingList_BookingList_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface Q_Admin_BookingList_BookingList_page {
  __typename: "Page";
  /**
   * 현재 페이지 번호
   */
  page: number;
  /**
   * 페이지당 문서 갯수
   */
  cntPerPage: number;
  /**
   * 총 갯수
   */
  totalCount: number;
}

export interface Q_Admin_BookingList_BookingList_data_profile {
  __typename: "Profile";
  /**
   * 이름
   */
  name: string;
  /**
   * 인스타그램 url
   */
  instagramURL: string;
}

export interface Q_Admin_BookingList_BookingList_data_schedule {
  __typename: "Schedule";
  _id: string;
}

export interface Q_Admin_BookingList_BookingList_data_latestRequestHistory {
  __typename: "RequestHistory";
  reason: string;
}

export interface Q_Admin_BookingList_BookingList_data {
  __typename: "Booking";
  _id: string;
  createdAt: any;
  updatedAt: any;
  /**
   * 예약 날짜
   */
  bookingDate: any;
  /**
   * 가격
   */
  price: number;
  /**
   * 소상공인 이름
   */
  bookerName: string;
  /**
   * 상점명
   */
  storeName: string;
  /**
   * 상태
   */
  status: BookingStatus;
  /**
   * 거절요청 된건지
   */
  isRejectReqCancel: boolean | null;
  /**
   * 취소 요청 가능한지, 기준 => 예약일로부터 2주 
   */
  isPossibleBookingCancel: boolean;
  /**
   * 프로필
   */
  profile: Q_Admin_BookingList_BookingList_data_profile;
  /**
   * 스케줄
   */
  schedule: Q_Admin_BookingList_BookingList_data_schedule;
  latestRequestHistory: Q_Admin_BookingList_BookingList_data_latestRequestHistory | null;
}

export interface Q_Admin_BookingList_BookingList_supply {
  __typename: "BookingListSupply";
  paymentPrice: number;
  cancelPrice: number;
}

export interface Q_Admin_BookingList_BookingList {
  __typename: "BookingListResponse";
  ok: boolean;
  error: Q_Admin_BookingList_BookingList_error | null;
  page: Q_Admin_BookingList_BookingList_page;
  data: Q_Admin_BookingList_BookingList_data[];
  supply: Q_Admin_BookingList_BookingList_supply;
}

export interface Q_Admin_BookingList {
  BookingList: Q_Admin_BookingList_BookingList;
}

export interface Q_Admin_BookingListVariables {
  sort?: _BookingSort[] | null;
  filter?: _BookingFilter | null;
  pageInput: pageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Q_Admin_StoreList
// ====================================================

export interface Q_Admin_StoreList_StoreList_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface Q_Admin_StoreList_StoreList_page {
  __typename: "Page";
  /**
   * 현재 페이지 번호
   */
  page: number;
  /**
   * 페이지당 문서 갯수
   */
  cntPerPage: number;
  /**
   * 페이지 총 갯수
   */
  totalPageSize: number;
  /**
   * 총 갯수
   */
  totalCount: number;
}

export interface Q_Admin_StoreList_StoreList_data {
  __typename: "Store";
  _id: string;
  /**
   * 상점명
   */
  storeName: string;
  /**
   * 상점 주소
   */
  address: string;
  /**
   * 상점 연락처
   */
  phoneNumber: string;
  /**
   * 가게 ex) 식당, 도소매업
   */
  buisnessRange: string;
  /**
   * 상점 소개
   */
  introduce: string;
  /**
   * 네이버 스마트플레이스 url
   */
  naverSmartPlaceUrl: string | null;
}

export interface Q_Admin_StoreList_StoreList {
  __typename: "StoreListResponse";
  ok: boolean;
  error: Q_Admin_StoreList_StoreList_error | null;
  page: Q_Admin_StoreList_StoreList_page;
  data: Q_Admin_StoreList_StoreList_data[];
}

export interface Q_Admin_StoreList {
  StoreList: Q_Admin_StoreList_StoreList;
}

export interface Q_Admin_StoreListVariables {
  sort?: _StoreSort[] | null;
  filter?: _StoreFilter | null;
  pageInput: pageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Q_Admin_UserList
// ====================================================

export interface Q_Admin_UserList_UserList_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface Q_Admin_UserList_UserList_page {
  __typename: "Page";
  /**
   * 현재 페이지 번호
   */
  page: number;
  /**
   * 페이지당 문서 갯수
   */
  cntPerPage: number;
  /**
   * 페이지 총 갯수
   */
  totalPageSize: number;
  /**
   * 총 갯수
   */
  totalCount: number;
}

export interface Q_Admin_UserList_UserList_data_profile_profileImage {
  __typename: "File";
  uri: string;
  x: number | null;
  y: number | null;
}

export interface Q_Admin_UserList_UserList_data_profile_categories {
  __typename: "Category";
  _id: string;
  /**
   * 이름
   */
  name: string;
}

export interface Q_Admin_UserList_UserList_data_profile_sns {
  __typename: "SNS";
  label: string;
  value: string;
}

export interface Q_Admin_UserList_UserList_data_profile {
  __typename: "Profile";
  _id: string;
  /**
   * 이름
   */
  name: string;
  /**
   * 프로필 이미지
   */
  profileImage: Q_Admin_UserList_UserList_data_profile_profileImage | null;
  /**
   * 카테고리
   */
  categories: Q_Admin_UserList_UserList_data_profile_categories[];
  /**
   * sns url
   */
  sns: Q_Admin_UserList_UserList_data_profile_sns[];
  bookingTotalCnt: number;
  /**
   * 개인 홈페이지 url
   */
  homepageURL: string;
  /**
   * 본인 소개
   */
  introduce: string;
  /**
   * 프로필 공개 여부
   */
  isOpen: boolean;
  price: number;
  /**
   * 싫어 하는 음식 리스트
   */
  unfavorList: string | null;
  /**
   * 핸드폰번호
   */
  phoneNumber: string;
  /**
   * 활동 가능 지역
   */
  region: string;
  influencerCode: string;
}

export interface Q_Admin_UserList_UserList_data {
  __typename: "User";
  _id: string;
  /**
   * 최근에 로그인한 날짜
   */
  lasteLogInDate: any | null;
  /**
   * 이메일
   */
  email: string;
  /**
   * 인플루언서 회원가입후 상태값
   */
  status: UserStatus;
  profile: Q_Admin_UserList_UserList_data_profile | null;
}

export interface Q_Admin_UserList_UserList {
  __typename: "UserListResponse";
  ok: boolean;
  error: Q_Admin_UserList_UserList_error | null;
  page: Q_Admin_UserList_UserList_page;
  data: Q_Admin_UserList_UserList_data[];
}

export interface Q_Admin_UserList {
  UserList: Q_Admin_UserList_UserList;
}

export interface Q_Admin_UserListVariables {
  notLoginDay?: number | null;
  sort?: _UserSort[] | null;
  filter?: _UserFilter | null;
  pageInput: pageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Q_Admin_SpecialList
// ====================================================

export interface Q_Admin_SpecialList_RecommandProfileFindOne_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface Q_Admin_SpecialList_RecommandProfileFindOne_data_profiles_profileImage {
  __typename: "File";
  uri: string;
  x: number | null;
  y: number | null;
}

export interface Q_Admin_SpecialList_RecommandProfileFindOne_data_profiles_categories {
  __typename: "Category";
  _id: string;
  /**
   * 이름
   */
  name: string;
}

export interface Q_Admin_SpecialList_RecommandProfileFindOne_data_profiles {
  __typename: "Profile";
  _id: string;
  /**
   * 이름
   */
  name: string;
  /**
   * 인플루언서 이메일, 검색을 위해 필요
   */
  influencerEmail: string;
  /**
   * 프로필 이미지
   */
  profileImage: Q_Admin_SpecialList_RecommandProfileFindOne_data_profiles_profileImage | null;
  /**
   * 카테고리
   */
  categories: Q_Admin_SpecialList_RecommandProfileFindOne_data_profiles_categories[];
  /**
   * 개인 홈페이지 url
   */
  homepageURL: string;
  /**
   * 인스타그램 url
   */
  instagramURL: string;
  /**
   * 본인 소개
   */
  introduce: string;
  /**
   * 프로필 공개 여부
   */
  isOpen: boolean;
  price: number;
  /**
   * 싫어 하는 음식 리스트
   */
  unfavorList: string | null;
}

export interface Q_Admin_SpecialList_RecommandProfileFindOne_data {
  __typename: "RecommandProfile";
  _id: string;
  isDisplay: boolean | null;
  /**
   * 최소로 출력해야 하는 프로필 갯수, 최소 갯수 미만이면 리스트 출력 X
   */
  minCount: number | null;
  /**
   * 출력할수 있는 최대 프로필 갯수,
   */
  maxCount: number | null;
  /**
   * 카테고리
   */
  profiles: Q_Admin_SpecialList_RecommandProfileFindOne_data_profiles[] | null;
}

export interface Q_Admin_SpecialList_RecommandProfileFindOne {
  __typename: "RecommandProfileFindOneResponse";
  ok: boolean;
  error: Q_Admin_SpecialList_RecommandProfileFindOne_error | null;
  data: Q_Admin_SpecialList_RecommandProfileFindOne_data | null;
}

export interface Q_Admin_SpecialList {
  RecommandProfileFindOne: Q_Admin_SpecialList_RecommandProfileFindOne;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Q_Admin_ProfileList
// ====================================================

export interface Q_Admin_ProfileList_ProfileList_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface Q_Admin_ProfileList_ProfileList_page {
  __typename: "Page";
  /**
   * 현재 페이지 번호
   */
  page: number;
  /**
   * 페이지당 문서 갯수
   */
  cntPerPage: number;
  /**
   * 페이지 총 갯수
   */
  totalPageSize: number;
  /**
   * 총 갯수
   */
  totalCount: number;
}

export interface Q_Admin_ProfileList_ProfileList_data_categories {
  __typename: "Category";
  _id: string;
  /**
   * 이름
   */
  name: string;
}

export interface Q_Admin_ProfileList_ProfileList_data_profileImage {
  __typename: "File";
  uri: string;
}

export interface Q_Admin_ProfileList_ProfileList_data {
  __typename: "Profile";
  _id: string;
  /**
   * 프로필 공개 여부
   */
  isOpen: boolean;
  /**
   * 이름
   */
  name: string;
  /**
   * 인스타그램 url
   */
  instagramURL: string;
  /**
   * 본인 소개
   */
  introduce: string;
  bookingTotalCnt: number;
  /**
   * 카테고리
   */
  categories: Q_Admin_ProfileList_ProfileList_data_categories[];
  price: number;
  /**
   * 프로필 이미지
   */
  profileImage: Q_Admin_ProfileList_ProfileList_data_profileImage | null;
}

export interface Q_Admin_ProfileList_ProfileList {
  __typename: "ProfileListResponse";
  ok: boolean;
  error: Q_Admin_ProfileList_ProfileList_error | null;
  page: Q_Admin_ProfileList_ProfileList_page;
  data: Q_Admin_ProfileList_ProfileList_data[];
}

export interface Q_Admin_ProfileList {
  ProfileList: Q_Admin_ProfileList_ProfileList;
}

export interface Q_Admin_ProfileListVariables {
  sort?: _ProfileSort[] | null;
  filter?: _ProfileFilter | null;
  pageInput: pageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Q_Admin_SmsTemplateList
// ====================================================

export interface Q_Admin_SmsTemplateList_SmsTemplateList_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface Q_Admin_SmsTemplateList_SmsTemplateList_page {
  __typename: "Page";
  /**
   * 현재 페이지 번호
   */
  page: number;
  /**
   * 페이지당 문서 갯수
   */
  cntPerPage: number;
  /**
   * 페이지 총 갯수
   */
  totalPageSize: number;
  /**
   * 총 갯수
   */
  totalCount: number;
}

export interface Q_Admin_SmsTemplateList_SmsTemplateList_data_trigger_tags {
  __typename: "Tag";
  key: string;
  value: string;
}

export interface Q_Admin_SmsTemplateList_SmsTemplateList_data_trigger {
  __typename: "NotificationTrigger";
  _id: string;
  sender: string;
  event: NotificationTriggerEvent;
  isEnabled: boolean;
  tags: Q_Admin_SmsTemplateList_SmsTemplateList_data_trigger_tags[];
}

export interface Q_Admin_SmsTemplateList_SmsTemplateList_data_tags {
  __typename: "Tag";
  key: string;
  value: string;
}

export interface Q_Admin_SmsTemplateList_SmsTemplateList_data {
  __typename: "TemplateSms";
  _id: string;
  createdAt: any;
  updatedAt: any;
  name: string;
  description: string | null;
  content: string;
  trigger: Q_Admin_SmsTemplateList_SmsTemplateList_data_trigger | null;
  replacers: string[];
  tags: Q_Admin_SmsTemplateList_SmsTemplateList_data_tags[];
}

export interface Q_Admin_SmsTemplateList_SmsTemplateList {
  __typename: "SmsTemplateListResponse";
  ok: boolean;
  error: Q_Admin_SmsTemplateList_SmsTemplateList_error | null;
  page: Q_Admin_SmsTemplateList_SmsTemplateList_page;
  data: Q_Admin_SmsTemplateList_SmsTemplateList_data[];
}

export interface Q_Admin_SmsTemplateList {
  SmsTemplateList: Q_Admin_SmsTemplateList_SmsTemplateList;
}

export interface Q_Admin_SmsTemplateListVariables {
  sort?: _ITemplateSort[] | null;
  filter?: _ITemplateFilter | null;
  pageInput: pageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Q_Admin_SettlementList
// ====================================================

export interface Q_Admin_SettlementList_SettlementList_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface Q_Admin_SettlementList_SettlementList_page {
  __typename: "Page";
  /**
   * 현재 페이지 번호
   */
  page: number;
  /**
   * 페이지당 문서 갯수
   */
  cntPerPage: number;
  /**
   * 페이지 총 갯수
   */
  totalPageSize: number;
  /**
   * 총 갯수
   */
  totalCount: number;
}

export interface Q_Admin_SettlementList_SettlementList_data_requestHistory {
  __typename: "RequestHistory";
  date: any;
  reqType: RequestStatus;
}

export interface Q_Admin_SettlementList_SettlementList_data_bookings {
  __typename: "Booking";
  _id: string;
  /**
   * 상태
   */
  status: BookingStatus;
  /**
   * 상점명
   */
  storeName: string;
  /**
   * 예약 날짜
   */
  bookingDate: any;
  /**
   * 가격
   */
  price: number;
}

export interface Q_Admin_SettlementList_SettlementList_data_feePolicy_addtionalFees {
  __typename: "AddtionalFees";
  feeName: string;
  feePercent: number;
  fee: number;
}

export interface Q_Admin_SettlementList_SettlementList_data_feePolicy {
  __typename: "FeePolicy";
  addtionalFees: Q_Admin_SettlementList_SettlementList_data_feePolicy_addtionalFees[];
}

export interface Q_Admin_SettlementList_SettlementList_data {
  __typename: "Settlement";
  _id: string;
  status: SettlementStatus | null;
  /**
   * 이름
   */
  name: string;
  /**
   * 인플루언서 연락처
   */
  phoneNumber: string | null;
  /**
   * 인플루언서 이메일
   */
  influencerEmail: string | null;
  /**
   * 은행 ex) 국민, 우리, 부산
   */
  bankName: string | null;
  /**
   * 계좌 번호
   */
  accNumber: string | null;
  /**
   * 예금주명
   */
  bankOwnerName: string | null;
  requestHistory: Q_Admin_SettlementList_SettlementList_data_requestHistory[];
  bookings: Q_Admin_SettlementList_SettlementList_data_bookings[] | null;
  /**
   * 예약갯수
   */
  bookingsCnt: number;
  /**
   * 정산 시작 일자
   */
  startDate: any;
  /**
   * 정산 종료 일자
   */
  endDate: any;
  /**
   * 총 판매액
   */
  totalPrice: number | null;
  feePolicy: Q_Admin_SettlementList_SettlementList_data_feePolicy;
  /**
   * 수수료 총합
   */
  additionFeeSum: number | null;
  /**
   * 수수료 뺀 정산 금액
   */
  settlePrice: number | null;
}

export interface Q_Admin_SettlementList_SettlementList_supply {
  __typename: "SettlementListSupply";
  bookingPriceTotal: number;
  feeTotal: number;
  settlePriceTotal: number;
}

export interface Q_Admin_SettlementList_SettlementList {
  __typename: "SettlementListResponse";
  ok: boolean;
  error: Q_Admin_SettlementList_SettlementList_error | null;
  page: Q_Admin_SettlementList_SettlementList_page;
  data: Q_Admin_SettlementList_SettlementList_data[];
  supply: Q_Admin_SettlementList_SettlementList_supply;
}

export interface Q_Admin_SettlementList {
  SettlementList: Q_Admin_SettlementList_SettlementList;
}

export interface Q_Admin_SettlementListVariables {
  sort?: _SettlementSort[] | null;
  filter?: _SettlementFilter | null;
  pageInput: pageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Q_Admin_FeePolicyFindOne
// ====================================================

export interface Q_Admin_FeePolicyFindOne_FeePolicyFindOne_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface Q_Admin_FeePolicyFindOne_FeePolicyFindOne_data_addtionalFees {
  __typename: "AddtionalFees";
  feeName: string;
  target: TargetStatus;
  type: AddtionalFeesStatus;
  feePercent: number;
  fee: number;
}

export interface Q_Admin_FeePolicyFindOne_FeePolicyFindOne_data {
  __typename: "FeePolicy";
  _id: string;
  status: FeePolicyStatus;
  addtionalFees: Q_Admin_FeePolicyFindOne_FeePolicyFindOne_data_addtionalFees[];
}

export interface Q_Admin_FeePolicyFindOne_FeePolicyFindOne {
  __typename: "FeePolicyFindOneResponse";
  ok: boolean;
  error: Q_Admin_FeePolicyFindOne_FeePolicyFindOne_error | null;
  data: Q_Admin_FeePolicyFindOne_FeePolicyFindOne_data | null;
}

export interface Q_Admin_FeePolicyFindOne {
  FeePolicyFindOne: Q_Admin_FeePolicyFindOne_FeePolicyFindOne;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Q_SUPER_PageInfoList
// ====================================================

export interface Q_SUPER_PageInfoList_PageInfoList_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface Q_SUPER_PageInfoList_PageInfoList_data {
  __typename: "PageInfo";
  _id: string;
  key: string;
  value: any;
}

export interface Q_SUPER_PageInfoList_PageInfoList {
  __typename: "PageInfoListResponse";
  ok: boolean;
  error: Q_SUPER_PageInfoList_PageInfoList_error | null;
  data: Q_SUPER_PageInfoList_PageInfoList_data[];
}

export interface Q_SUPER_PageInfoList {
  PageInfoList: Q_SUPER_PageInfoList_PageInfoList;
}

export interface Q_SUPER_PageInfoListVariables {
  sort?: _PageInfoSort[] | null;
  filter?: _PageInfoFilter | null;
  pageInput: pageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_VerificationStart
// ====================================================

export interface M_VerificationStart_VerificationStart_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_VerificationStart_VerificationStart_data {
  __typename: "Verification";
  _id: string;
  target: VerificationTarget;
  payload: string;
}

export interface M_VerificationStart_VerificationStart {
  __typename: "VerificationResponse";
  ok: boolean;
  error: M_VerificationStart_VerificationStart_error | null;
  data: M_VerificationStart_VerificationStart_data | null;
}

export interface M_VerificationStart {
  /**
   * 인증 요청을 하는 함수, 이메일 또는 핸드폰으로 인증번호를 발신하고 인증 테이블에 요청 추가함
   */
  VerificationStart: M_VerificationStart_VerificationStart;
}

export interface M_VerificationStartVariables {
  target: VerificationTarget;
  payload: string;
  event: VerificationEvent;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_VerificationComplete
// ====================================================

export interface M_VerificationComplete_VerificationComplete_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_VerificationComplete_VerificationComplete_data {
  __typename: "Verification";
  _id: string;
  target: VerificationTarget;
  payload: string;
}

export interface M_VerificationComplete_VerificationComplete {
  __typename: "VerificationResponse";
  ok: boolean;
  error: M_VerificationComplete_VerificationComplete_error | null;
  data: M_VerificationComplete_VerificationComplete_data | null;
}

export interface M_VerificationComplete {
  VerificationComplete: M_VerificationComplete_VerificationComplete;
}

export interface M_VerificationCompleteVariables {
  verificationId: string;
  target: VerificationTarget;
  code: string;
  payload: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SingleUpload
// ====================================================

export interface SingleUpload_SingleUpload_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface SingleUpload_SingleUpload_data {
  __typename: "File";
  name: string;
  description: string | null;
  extension: string | null;
  fileType: string | null;
  uri: string;
  owner: string;
}

export interface SingleUpload_SingleUpload {
  __typename: "FileUploadResponse";
  ok: boolean;
  error: SingleUpload_SingleUpload_error | null;
  data: SingleUpload_SingleUpload_data | null;
}

export interface SingleUpload {
  SingleUpload: SingleUpload_SingleUpload;
}

export interface SingleUploadVariables {
  file: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PasswordResetByMe
// ====================================================

export interface PasswordResetByMe_PasswordResetByMe_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface PasswordResetByMe_PasswordResetByMe_data {
  __typename: "PasswordResetByMe";
  resultObj: any;
}

export interface PasswordResetByMe_PasswordResetByMe {
  __typename: "PasswordResetByMeResponse";
  ok: boolean;
  error: PasswordResetByMe_PasswordResetByMe_error | null;
  data: PasswordResetByMe_PasswordResetByMe_data | null;
}

export interface PasswordResetByMe {
  PasswordResetByMe: PasswordResetByMe_PasswordResetByMe;
}

export interface PasswordResetByMeVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PasswordChangeByMe
// ====================================================

export interface PasswordChangeByMe_PasswordChangeByMe_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface PasswordChangeByMe_PasswordChangeByMe_data {
  __typename: "User";
  /**
   * 이름
   */
  name: string;
}

export interface PasswordChangeByMe_PasswordChangeByMe {
  __typename: "PasswordChangeByMeResponse";
  ok: boolean;
  error: PasswordChangeByMe_PasswordChangeByMe_error | null;
  data: PasswordChangeByMe_PasswordChangeByMe_data | null;
}

export interface PasswordChangeByMe {
  PasswordChangeByMe: PasswordChangeByMe_PasswordChangeByMe;
}

export interface PasswordChangeByMeVariables {
  currentPw: string;
  newPasswordConfirm: string;
  newPassword: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PasswordChangeByAdmin
// ====================================================

export interface PasswordChangeByAdmin_PasswordChangeByAdmin_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface PasswordChangeByAdmin_PasswordChangeByAdmin_data {
  __typename: "User";
  /**
   * 이름
   */
  name: string;
  /**
   * 이메일
   */
  email: string;
}

export interface PasswordChangeByAdmin_PasswordChangeByAdmin {
  __typename: "PasswordChangeByAdminResponse";
  ok: boolean;
  error: PasswordChangeByAdmin_PasswordChangeByAdmin_error | null;
  data: PasswordChangeByAdmin_PasswordChangeByAdmin_data | null;
}

export interface PasswordChangeByAdmin {
  PasswordChangeByAdmin: PasswordChangeByAdmin_PasswordChangeByAdmin;
}

export interface PasswordChangeByAdminVariables {
  newPasswordConfirm: string;
  newPassword: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SignUp
// ====================================================

export interface SignUp_SignUp_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface SignUp_SignUp_data {
  __typename: "User";
  /**
   * 이메일
   */
  email: string;
}

export interface SignUp_SignUp {
  __typename: "SignUpResponse";
  ok: boolean;
  error: SignUp_SignUp_error | null;
  data: SignUp_SignUp_data | null;
}

export interface SignUp {
  SignUp: SignUp_SignUp;
}

export interface SignUpVariables {
  verificationId: string;
  params: SignUpInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_BookingCancelReq
// ====================================================

export interface M_BookingCancelReq_BookingCancelReq_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_BookingCancelReq_BookingCancelReq_data {
  __typename: "Booking";
  _id: string;
  /**
   * 상태
   */
  status: BookingStatus;
  /**
   * 소상공인 이름
   */
  bookerName: string;
  /**
   * 상점명
   */
  storeName: string;
}

export interface M_BookingCancelReq_BookingCancelReq {
  __typename: "BookingCancelReqResponse";
  ok: boolean;
  error: M_BookingCancelReq_BookingCancelReq_error | null;
  data: M_BookingCancelReq_BookingCancelReq_data | null;
}

export interface M_BookingCancelReq {
  BookingCancelReq: M_BookingCancelReq_BookingCancelReq;
}

export interface M_BookingCancelReqVariables {
  id: string;
  cancelReqReason: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ScheduleUpdateByMe
// ====================================================

export interface ScheduleUpdateByMe_ScheduleUpdateByMe_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface ScheduleUpdateByMe_ScheduleUpdateByMe_data_influencer {
  __typename: "User";
  _id: string;
}

export interface ScheduleUpdateByMe_ScheduleUpdateByMe_data {
  __typename: "Schedule";
  _id: string;
  createdAt: any;
  updatedAt: any;
  isDelete: boolean;
  influencer: ScheduleUpdateByMe_ScheduleUpdateByMe_data_influencer;
  /**
   * 인플루언서 이메일, 검색을 위해 필요
   */
  influencerEmail: string;
  /**
   * 요일막기, 무슨 요일에 장사안하는지 ex) [0, 3, 4]
   */
  day: number[];
  /**
   * 인플루언서가 장사안하는 날짜목록 ex) ['2020-10-10', '2020=10-11']
   */
  self: any[];
  /**
   * 소상공인이 예약한 날짜목록 ex) ['2020-10-10', '2020=10-11']
   */
  book: any[];
}

export interface ScheduleUpdateByMe_ScheduleUpdateByMe {
  __typename: "ScheduleUpdateByMeResponse";
  ok: boolean;
  error: ScheduleUpdateByMe_ScheduleUpdateByMe_error | null;
  data: ScheduleUpdateByMe_ScheduleUpdateByMe_data | null;
}

export interface ScheduleUpdateByMe {
  ScheduleUpdateByMe: ScheduleUpdateByMe_ScheduleUpdateByMe;
}

export interface ScheduleUpdateByMeVariables {
  params: ScheduleUpdateByMeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ScheduleUpdateByMeHours
// ====================================================

export interface ScheduleUpdateByMeHours_ScheduleUpdateByMe_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface ScheduleUpdateByMeHours_ScheduleUpdateByMe_data_influencer {
  __typename: "User";
  _id: string;
  /**
   * 이름
   */
  name: string;
}

export interface ScheduleUpdateByMeHours_ScheduleUpdateByMe_data_monday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface ScheduleUpdateByMeHours_ScheduleUpdateByMe_data_tuesday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface ScheduleUpdateByMeHours_ScheduleUpdateByMe_data_wednesday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface ScheduleUpdateByMeHours_ScheduleUpdateByMe_data_thursday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface ScheduleUpdateByMeHours_ScheduleUpdateByMe_data_friday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface ScheduleUpdateByMeHours_ScheduleUpdateByMe_data_saturday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface ScheduleUpdateByMeHours_ScheduleUpdateByMe_data_sunday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface ScheduleUpdateByMeHours_ScheduleUpdateByMe_data {
  __typename: "Schedule";
  _id: string;
  influencer: ScheduleUpdateByMeHours_ScheduleUpdateByMe_data_influencer;
  /**
   * 월요일 시작 ~ 종료 시간
   */
  monday: ScheduleUpdateByMeHours_ScheduleUpdateByMe_data_monday;
  /**
   * 화요일 시작 ~ 종료 시간
   */
  tuesday: ScheduleUpdateByMeHours_ScheduleUpdateByMe_data_tuesday;
  /**
   * 수요일 시작 ~ 종료 시간
   */
  wednesday: ScheduleUpdateByMeHours_ScheduleUpdateByMe_data_wednesday;
  /**
   * 목요일 시작 ~ 종료 시간
   */
  thursday: ScheduleUpdateByMeHours_ScheduleUpdateByMe_data_thursday;
  /**
   * 금요일 시작 ~ 종료 시간
   */
  friday: ScheduleUpdateByMeHours_ScheduleUpdateByMe_data_friday;
  /**
   * 토요일 시작 ~ 종료 시간
   */
  saturday: ScheduleUpdateByMeHours_ScheduleUpdateByMe_data_saturday;
  /**
   * 일요일 시작 ~ 종료 시간
   */
  sunday: ScheduleUpdateByMeHours_ScheduleUpdateByMe_data_sunday;
}

export interface ScheduleUpdateByMeHours_ScheduleUpdateByMe {
  __typename: "ScheduleUpdateByMeResponse";
  ok: boolean;
  error: ScheduleUpdateByMeHours_ScheduleUpdateByMe_error | null;
  data: ScheduleUpdateByMeHours_ScheduleUpdateByMe_data | null;
}

export interface ScheduleUpdateByMeHours {
  ScheduleUpdateByMe: ScheduleUpdateByMeHours_ScheduleUpdateByMe;
}

export interface ScheduleUpdateByMeHoursVariables {
  params: ScheduleUpdateByMeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProfileUpdateByMe
// ====================================================

export interface ProfileUpdateByMe_ProfileUpdateByMe_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface ProfileUpdateByMe_ProfileUpdateByMe_data_profileImage {
  __typename: "File";
  _id: string;
  name: string;
  description: string | null;
  extension: string | null;
  fileType: string | null;
  uri: string;
  owner: string;
  x: number | null;
  y: number | null;
}

export interface ProfileUpdateByMe_ProfileUpdateByMe_data_categories {
  __typename: "Category";
  /**
   * 이름
   */
  name: string;
}

export interface ProfileUpdateByMe_ProfileUpdateByMe_data_sns {
  __typename: "SNS";
  label: string;
  value: string;
}

export interface ProfileUpdateByMe_ProfileUpdateByMe_data {
  __typename: "Profile";
  _id: string;
  /**
   * 프로필 이미지
   */
  profileImage: ProfileUpdateByMe_ProfileUpdateByMe_data_profileImage | null;
  /**
   * 카테고리
   */
  categories: ProfileUpdateByMe_ProfileUpdateByMe_data_categories[];
  /**
   * 개인 홈페이지 url
   */
  homepageURL: string;
  /**
   * sns url
   */
  sns: ProfileUpdateByMe_ProfileUpdateByMe_data_sns[];
  /**
   * 본인 소개
   */
  introduce: string;
  /**
   * 프로필 공개 여부
   */
  isOpen: boolean;
}

export interface ProfileUpdateByMe_ProfileUpdateByMe {
  __typename: "ProfileUpdateByMeResponse";
  ok: boolean;
  error: ProfileUpdateByMe_ProfileUpdateByMe_error | null;
  data: ProfileUpdateByMe_ProfileUpdateByMe_data | null;
}

export interface ProfileUpdateByMe {
  ProfileUpdateByMe: ProfileUpdateByMe_ProfileUpdateByMe;
}

export interface ProfileUpdateByMeVariables {
  params: ProfileUpdateByMeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_AccountUpdateByMe
// ====================================================

export interface M_AccountUpdateByMe_AccountUpdateByMe_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_AccountUpdateByMe_AccountUpdateByMe_data {
  __typename: "Account";
  /**
   * 은행 ex) 국민, 우리, 부산
   */
  bankName: string | null;
  /**
   * 계좌 번호
   */
  accNumber: string | null;
  /**
   * 예금주명
   */
  bankOwnerName: string | null;
}

export interface M_AccountUpdateByMe_AccountUpdateByMe {
  __typename: "AccountUpdateByMeResponse";
  ok: boolean;
  error: M_AccountUpdateByMe_AccountUpdateByMe_error | null;
  data: M_AccountUpdateByMe_AccountUpdateByMe_data | null;
}

export interface M_AccountUpdateByMe {
  AccountUpdateByMe: M_AccountUpdateByMe_AccountUpdateByMe;
}

export interface M_AccountUpdateByMeVariables {
  params: AccountUpdateByMeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BusinessSignIn
// ====================================================

export interface BusinessSignIn_BusinessSignIn_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface BusinessSignIn_BusinessSignIn_data {
  __typename: "BusinessSignIn";
  token: string | null;
  verificationId: string | null;
}

export interface BusinessSignIn_BusinessSignIn {
  __typename: "BusinessSignInResponse";
  ok: boolean;
  error: BusinessSignIn_BusinessSignIn_error | null;
  data: BusinessSignIn_BusinessSignIn_data | null;
}

export interface BusinessSignIn {
  BusinessSignIn: BusinessSignIn_BusinessSignIn;
}

export interface BusinessSignInVariables {
  params: BusinessSignInArgs;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BusinessSignInUpdate
// ====================================================

export interface BusinessSignInUpdate_BusinessSignInUpdate_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface BusinessSignInUpdate_BusinessSignInUpdate_data {
  __typename: "BusinessSignInUpdate";
  token: string;
}

export interface BusinessSignInUpdate_BusinessSignInUpdate {
  __typename: "BusinessSignInUpdateResponse";
  ok: boolean;
  error: BusinessSignInUpdate_BusinessSignInUpdate_error | null;
  data: BusinessSignInUpdate_BusinessSignInUpdate_data | null;
}

export interface BusinessSignInUpdate {
  BusinessSignInUpdate: BusinessSignInUpdate_BusinessSignInUpdate;
}

export interface BusinessSignInUpdateVariables {
  params: BusinessSignInUpdateArgs;
  verificationId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_StoreCreate
// ====================================================

export interface M_StoreCreate_StoreCreate_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_StoreCreate_StoreCreate_data {
  __typename: "Store";
  _id: string;
  /**
   * 상점명
   */
  storeName: string;
}

export interface M_StoreCreate_StoreCreate {
  __typename: "StoreCreateResponse";
  ok: boolean;
  error: M_StoreCreate_StoreCreate_error | null;
  data: M_StoreCreate_StoreCreate_data | null;
}

export interface M_StoreCreate {
  StoreCreate: M_StoreCreate_StoreCreate;
}

export interface M_StoreCreateVariables {
  params: StoreCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_StoreUpdate
// ====================================================

export interface M_StoreUpdate_StoreUpdate_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_StoreUpdate_StoreUpdate_data {
  __typename: "Store";
  _id: string;
  /**
   * 상점명
   */
  storeName: string;
  /**
   * 상점 주소
   */
  address: string;
  /**
   * 상점 연락처
   */
  phoneNumber: string;
}

export interface M_StoreUpdate_StoreUpdate {
  __typename: "StoreUpdateResponse";
  ok: boolean;
  error: M_StoreUpdate_StoreUpdate_error | null;
  data: M_StoreUpdate_StoreUpdate_data | null;
}

export interface M_StoreUpdate {
  StoreUpdate: M_StoreUpdate_StoreUpdate;
}

export interface M_StoreUpdateVariables {
  params: StoreUpdateInput;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_StoreDelete
// ====================================================

export interface M_StoreDelete_StoreDelete_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_StoreDelete_StoreDelete_data {
  __typename: "Store";
  _id: string;
  /**
   * 상점명
   */
  storeName: string;
  /**
   * 상점 주소
   */
  address: string;
  /**
   * 상점 연락처
   */
  phoneNumber: string;
}

export interface M_StoreDelete_StoreDelete {
  __typename: "StoreDeleteResponse";
  ok: boolean;
  error: M_StoreDelete_StoreDelete_error | null;
  data: M_StoreDelete_StoreDelete_data | null;
}

export interface M_StoreDelete {
  StoreDelete: M_StoreDelete_StoreDelete;
}

export interface M_StoreDeleteVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_BasketItemCreate
// ====================================================

export interface M_BasketItemCreate_BasketItemCreate_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_BasketItemCreate_BasketItemCreate_data {
  __typename: "BasketItem";
  _id: string;
  /**
   * 가격
   */
  price: number;
  /**
   * 예약자 메모
   */
  bookerMemo: string;
}

export interface M_BasketItemCreate_BasketItemCreate {
  __typename: "BasketItemCreateResponse";
  ok: boolean;
  error: M_BasketItemCreate_BasketItemCreate_error | null;
  data: M_BasketItemCreate_BasketItemCreate_data | null;
}

export interface M_BasketItemCreate {
  BasketItemCreate: M_BasketItemCreate_BasketItemCreate;
}

export interface M_BasketItemCreateVariables {
  params: BasketItemCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_BasketItemDelete
// ====================================================

export interface M_BasketItemDelete_BasketItemDelete_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_BasketItemDelete_BasketItemDelete_data {
  __typename: "BasketItem";
  _id: string;
}

export interface M_BasketItemDelete_BasketItemDelete {
  __typename: "BasketItemDeleteResponse";
  ok: boolean;
  error: M_BasketItemDelete_BasketItemDelete_error | null;
  data: M_BasketItemDelete_BasketItemDelete_data | null;
}

export interface M_BasketItemDelete {
  BasketItemDelete: M_BasketItemDelete_BasketItemDelete;
}

export interface M_BasketItemDeleteVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_ReadyQueItemCreateForBasket
// ====================================================

export interface M_ReadyQueItemCreateForBasket_ReadyQueItemCreateForBasket_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_ReadyQueItemCreateForBasket_ReadyQueItemCreateForBasket_data {
  __typename: "ReadyQueItemCreateForBasket";
  groupCode: string;
  bookerEmail: string;
  bookerPhoneNumber: string;
  bookerName: string;
}

export interface M_ReadyQueItemCreateForBasket_ReadyQueItemCreateForBasket {
  __typename: "ReadyQueItemCreateForBasketResponse";
  ok: boolean;
  error: M_ReadyQueItemCreateForBasket_ReadyQueItemCreateForBasket_error | null;
  data: M_ReadyQueItemCreateForBasket_ReadyQueItemCreateForBasket_data | null;
}

export interface M_ReadyQueItemCreateForBasket {
  ReadyQueItemCreateForBasket: M_ReadyQueItemCreateForBasket_ReadyQueItemCreateForBasket;
}

export interface M_ReadyQueItemCreateForBasketVariables {
  basketIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_ReadyQueItemCreateForProfile
// ====================================================

export interface M_ReadyQueItemCreateForProfile_ReadyQueItemCreateForProfile_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_ReadyQueItemCreateForProfile_ReadyQueItemCreateForProfile_data {
  __typename: "ReadyQueItemCreateForProfile";
  groupCode: string;
  bookerEmail: string;
  bookerPhoneNumber: string;
  bookerName: string;
}

export interface M_ReadyQueItemCreateForProfile_ReadyQueItemCreateForProfile {
  __typename: "ReadyQueItemCreateForProfileResponse";
  ok: boolean;
  error: M_ReadyQueItemCreateForProfile_ReadyQueItemCreateForProfile_error | null;
  data: M_ReadyQueItemCreateForProfile_ReadyQueItemCreateForProfile_data | null;
}

export interface M_ReadyQueItemCreateForProfile {
  ReadyQueItemCreateForProfile: M_ReadyQueItemCreateForProfile_ReadyQueItemCreateForProfile;
}

export interface M_ReadyQueItemCreateForProfileVariables {
  params: ReadyQueItemCreateForProfileInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_BookingCancel
// ====================================================

export interface M_BookingCancel_BookingCancel_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_BookingCancel_BookingCancel_data_profile {
  __typename: "Profile";
  /**
   * 이름
   */
  name: string;
}

export interface M_BookingCancel_BookingCancel_data {
  __typename: "Booking";
  _id: string;
  /**
   * 프로필
   */
  profile: M_BookingCancel_BookingCancel_data_profile;
  /**
   * 예약 날짜
   */
  bookingDate: any;
}

export interface M_BookingCancel_BookingCancel {
  __typename: "BookingCancelResponse";
  ok: boolean;
  error: M_BookingCancel_BookingCancel_error | null;
  data: M_BookingCancel_BookingCancel_data | null;
}

export interface M_BookingCancel {
  BookingCancel: M_BookingCancel_BookingCancel;
}

export interface M_BookingCancelVariables {
  params: BookingCancelInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_BookingCancelReqWithdraw
// ====================================================

export interface M_BookingCancelReqWithdraw_BookingCancelReqWithdraw_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_BookingCancelReqWithdraw_BookingCancelReqWithdraw_data {
  __typename: "Booking";
  _id: string;
  /**
   * 예약 날짜
   */
  bookingDate: any;
  /**
   * 소상공인 이름
   */
  bookerName: string;
}

export interface M_BookingCancelReqWithdraw_BookingCancelReqWithdraw {
  __typename: "BookingCancelReqWithdrawResponse";
  ok: boolean;
  error: M_BookingCancelReqWithdraw_BookingCancelReqWithdraw_error | null;
  data: M_BookingCancelReqWithdraw_BookingCancelReqWithdraw_data | null;
}

export interface M_BookingCancelReqWithdraw {
  BookingCancelReqWithdraw: M_BookingCancelReqWithdraw_BookingCancelReqWithdraw;
}

export interface M_BookingCancelReqWithdrawVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_Admin_PriceUpdate
// ====================================================

export interface M_Admin_PriceUpdate_ProfileUpdateForSetPrice_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_Admin_PriceUpdate_ProfileUpdateForSetPrice_data {
  __typename: "Profile";
  _id: string;
  price: number;
}

export interface M_Admin_PriceUpdate_ProfileUpdateForSetPrice {
  __typename: "ProfileUpdateForSetPriceResponse";
  ok: boolean;
  error: M_Admin_PriceUpdate_ProfileUpdateForSetPrice_error | null;
  data: M_Admin_PriceUpdate_ProfileUpdateForSetPrice_data | null;
}

export interface M_Admin_PriceUpdate {
  ProfileUpdateForSetPrice: M_Admin_PriceUpdate_ProfileUpdateForSetPrice;
}

export interface M_Admin_PriceUpdateVariables {
  priceId: string;
  profileId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_UserStart
// ====================================================

export interface M_UserStart_UserStart_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_UserStart_UserStart_data {
  __typename: "User";
  _id: string;
  /**
   * 이름
   */
  name: string;
  /**
   * 회원가입 거절 사유
   */
  rejectReason: string;
  /**
   * 회원가입 거절 날짜
   */
  rejectDate: any | null;
}

export interface M_UserStart_UserStart {
  __typename: "UserStartResponse";
  ok: boolean;
  error: M_UserStart_UserStart_error | null;
  data: M_UserStart_UserStart_data | null;
}

export interface M_UserStart {
  UserStart: M_UserStart_UserStart;
}

export interface M_UserStartVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_UserStop
// ====================================================

export interface M_UserStop_UserStop_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_UserStop_UserStop_data {
  __typename: "User";
  _id: string;
  /**
   * 이름
   */
  name: string;
  /**
   * 회원가입 거절 사유
   */
  rejectReason: string;
  /**
   * 회원가입 거절 날짜
   */
  rejectDate: any | null;
}

export interface M_UserStop_UserStop {
  __typename: "UserStopResponse";
  ok: boolean;
  error: M_UserStop_UserStop_error | null;
  data: M_UserStop_UserStop_data | null;
}

export interface M_UserStop {
  UserStop: M_UserStop_UserStop;
}

export interface M_UserStopVariables {
  id: string;
  reason: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_UserDelete
// ====================================================

export interface M_UserDelete_UserDelete_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_UserDelete_UserDelete_data {
  __typename: "User";
  _id: string;
  /**
   * 이름
   */
  name: string;
  /**
   * 회원가입 거절 사유
   */
  rejectReason: string;
  /**
   * 회원가입 거절 날짜
   */
  rejectDate: any | null;
}

export interface M_UserDelete_UserDelete {
  __typename: "UserDeleteResponse";
  ok: boolean;
  error: M_UserDelete_UserDelete_error | null;
  data: M_UserDelete_UserDelete_data | null;
}

export interface M_UserDelete {
  UserDelete: M_UserDelete_UserDelete;
}

export interface M_UserDeleteVariables {
  id: string;
  reason: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_UserRecoveryFromDelete
// ====================================================

export interface M_UserRecoveryFromDelete_UserRecoveryFromDelete_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_UserRecoveryFromDelete_UserRecoveryFromDelete_data {
  __typename: "User";
  _id: string;
}

export interface M_UserRecoveryFromDelete_UserRecoveryFromDelete {
  __typename: "UserRecoveryFromDeleteResponse";
  ok: boolean;
  error: M_UserRecoveryFromDelete_UserRecoveryFromDelete_error | null;
  data: M_UserRecoveryFromDelete_UserRecoveryFromDelete_data | null;
}

export interface M_UserRecoveryFromDelete {
  UserRecoveryFromDelete: M_UserRecoveryFromDelete_UserRecoveryFromDelete;
}

export interface M_UserRecoveryFromDeleteVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_SpecialList_Update
// ====================================================

export interface M_SpecialList_Update_RecommandProfileUpdate_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_SpecialList_Update_RecommandProfileUpdate_data_profiles {
  __typename: "Profile";
  _id: string;
  /**
   * 이름
   */
  name: string;
}

export interface M_SpecialList_Update_RecommandProfileUpdate_data {
  __typename: "RecommandProfile";
  /**
   * 카테고리
   */
  profiles: M_SpecialList_Update_RecommandProfileUpdate_data_profiles[] | null;
}

export interface M_SpecialList_Update_RecommandProfileUpdate {
  __typename: "RecommandProfileUpdateResponse";
  ok: boolean;
  error: M_SpecialList_Update_RecommandProfileUpdate_error | null;
  data: M_SpecialList_Update_RecommandProfileUpdate_data | null;
}

export interface M_SpecialList_Update {
  RecommandProfileUpdate: M_SpecialList_Update_RecommandProfileUpdate;
}

export interface M_SpecialList_UpdateVariables {
  params: RecommandProfileUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_CategoryCreate
// ====================================================

export interface M_CategoryCreate_CategoryCreate_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_CategoryCreate_CategoryCreate_data {
  __typename: "Category";
  _id: string;
  /**
   * 이름
   */
  name: string;
}

export interface M_CategoryCreate_CategoryCreate {
  __typename: "CategoryCreateResponse";
  ok: boolean;
  error: M_CategoryCreate_CategoryCreate_error | null;
  data: M_CategoryCreate_CategoryCreate_data | null;
}

export interface M_CategoryCreate {
  CategoryCreate: M_CategoryCreate_CategoryCreate;
}

export interface M_CategoryCreateVariables {
  params: CategoryCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_CategoryUpdate
// ====================================================

export interface M_CategoryUpdate_CategoryUpdate_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_CategoryUpdate_CategoryUpdate_data {
  __typename: "Profile";
  _id: string;
  /**
   * 이름
   */
  name: string;
}

export interface M_CategoryUpdate_CategoryUpdate {
  __typename: "CategoryUpdateResponse";
  ok: boolean;
  error: M_CategoryUpdate_CategoryUpdate_error | null;
  data: M_CategoryUpdate_CategoryUpdate_data | null;
}

export interface M_CategoryUpdate {
  CategoryUpdate: M_CategoryUpdate_CategoryUpdate;
}

export interface M_CategoryUpdateVariables {
  id: string;
  params: CategoryUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_CategoryDelete
// ====================================================

export interface M_CategoryDelete_CategoryDelete_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_CategoryDelete_CategoryDelete_data {
  __typename: "Category";
  _id: string;
  /**
   * 이름
   */
  name: string;
}

export interface M_CategoryDelete_CategoryDelete {
  __typename: "CategoryDeleteResponse";
  ok: boolean;
  error: M_CategoryDelete_CategoryDelete_error | null;
  data: M_CategoryDelete_CategoryDelete_data | null;
}

export interface M_CategoryDelete {
  CategoryDelete: M_CategoryDelete_CategoryDelete;
}

export interface M_CategoryDeleteVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_PriceCreate
// ====================================================

export interface M_PriceCreate_PriceCreate_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_PriceCreate_PriceCreate_data {
  __typename: "Price";
  _id: string;
  /**
   * 인플루언서 가격
   */
  price: number;
}

export interface M_PriceCreate_PriceCreate {
  __typename: "PriceCreateResponse";
  ok: boolean;
  error: M_PriceCreate_PriceCreate_error | null;
  data: M_PriceCreate_PriceCreate_data | null;
}

export interface M_PriceCreate {
  PriceCreate: M_PriceCreate_PriceCreate;
}

export interface M_PriceCreateVariables {
  price: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_PriceUpdate
// ====================================================

export interface M_PriceUpdate_PriceUpdate_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_PriceUpdate_PriceUpdate_data {
  __typename: "Price";
  _id: string;
  /**
   * 인플루언서 가격
   */
  price: number;
}

export interface M_PriceUpdate_PriceUpdate {
  __typename: "PriceUpdateResponse";
  ok: boolean;
  error: M_PriceUpdate_PriceUpdate_error | null;
  data: M_PriceUpdate_PriceUpdate_data | null;
}

export interface M_PriceUpdate {
  PriceUpdate: M_PriceUpdate_PriceUpdate;
}

export interface M_PriceUpdateVariables {
  id: string;
  price: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_PriceDelete
// ====================================================

export interface M_PriceDelete_PriceDelete_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_PriceDelete_PriceDelete_data {
  __typename: "Price";
  _id: string;
  /**
   * 인플루언서 가격
   */
  price: number;
}

export interface M_PriceDelete_PriceDelete {
  __typename: "PriceDeleteResponse";
  ok: boolean;
  error: M_PriceDelete_PriceDelete_error | null;
  data: M_PriceDelete_PriceDelete_data | null;
}

export interface M_PriceDelete {
  PriceDelete: M_PriceDelete_PriceDelete;
}

export interface M_PriceDeleteVariables {
  altPriceId: string;
  delTargetId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_SignUpAccept
// ====================================================

export interface M_SignUpAccept_SignUpAccept_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_SignUpAccept_SignUpAccept_data {
  __typename: "User";
  _id: string;
  /**
   * 이름
   */
  name: string;
  /**
   * 이메일
   */
  email: string;
}

export interface M_SignUpAccept_SignUpAccept {
  __typename: "SignUpAcceptResponse";
  ok: boolean;
  error: M_SignUpAccept_SignUpAccept_error | null;
  data: M_SignUpAccept_SignUpAccept_data | null;
}

export interface M_SignUpAccept {
  SignUpAccept: M_SignUpAccept_SignUpAccept;
}

export interface M_SignUpAcceptVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_SignUpReject
// ====================================================

export interface M_SignUpReject_SignUpReject_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_SignUpReject_SignUpReject_data {
  __typename: "User";
  _id: string;
  /**
   * 이름
   */
  name: string;
  /**
   * 이메일
   */
  email: string;
}

export interface M_SignUpReject_SignUpReject {
  __typename: "SignUpRejectResponse";
  ok: boolean;
  error: M_SignUpReject_SignUpReject_error | null;
  data: M_SignUpReject_SignUpReject_data | null;
}

export interface M_SignUpReject {
  SignUpReject: M_SignUpReject_SignUpReject;
}

export interface M_SignUpRejectVariables {
  id: string;
  rejectReason: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_ProfileUpdateForSetPrice
// ====================================================

export interface M_ProfileUpdateForSetPrice_ProfileUpdateForSetPrice_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_ProfileUpdateForSetPrice_ProfileUpdateForSetPrice_data {
  __typename: "Profile";
  _id: string;
  /**
   * 이름
   */
  name: string;
  price: number;
}

export interface M_ProfileUpdateForSetPrice_ProfileUpdateForSetPrice {
  __typename: "ProfileUpdateForSetPriceResponse";
  ok: boolean;
  error: M_ProfileUpdateForSetPrice_ProfileUpdateForSetPrice_error | null;
  data: M_ProfileUpdateForSetPrice_ProfileUpdateForSetPrice_data | null;
}

export interface M_ProfileUpdateForSetPrice {
  ProfileUpdateForSetPrice: M_ProfileUpdateForSetPrice_ProfileUpdateForSetPrice;
}

export interface M_ProfileUpdateForSetPriceVariables {
  priceId: string;
  profileId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_SmsTemplateUpdate
// ====================================================

export interface M_SmsTemplateUpdate_SmsTemplateUpdate_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_SmsTemplateUpdate_SmsTemplateUpdate_data {
  __typename: "TemplateSms";
  _id: string;
  content: string;
}

export interface M_SmsTemplateUpdate_SmsTemplateUpdate {
  __typename: "SmsTemplateUpdateResponse";
  ok: boolean;
  error: M_SmsTemplateUpdate_SmsTemplateUpdate_error | null;
  data: M_SmsTemplateUpdate_SmsTemplateUpdate_data | null;
}

export interface M_SmsTemplateUpdate {
  SmsTemplateUpdate: M_SmsTemplateUpdate_SmsTemplateUpdate;
}

export interface M_SmsTemplateUpdateVariables {
  input: SmsTemplateUpdateInput;
  templateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_SettlementComplete
// ====================================================

export interface M_SettlementComplete_SettlementComplete_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_SettlementComplete_SettlementComplete_data {
  __typename: "Settlement";
  _id: string;
  status: SettlementStatus | null;
  /**
   * 이름
   */
  name: string;
}

export interface M_SettlementComplete_SettlementComplete {
  __typename: "SettlementCompleteResponse";
  ok: boolean;
  error: M_SettlementComplete_SettlementComplete_error | null;
  data: M_SettlementComplete_SettlementComplete_data | null;
}

export interface M_SettlementComplete {
  SettlementComplete: M_SettlementComplete_SettlementComplete;
}

export interface M_SettlementCompleteVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_SettlementCancel
// ====================================================

export interface M_SettlementCancel_SettlementCancel_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_SettlementCancel_SettlementCancel_data {
  __typename: "Settlement";
  _id: string;
  status: SettlementStatus | null;
  /**
   * 이름
   */
  name: string;
}

export interface M_SettlementCancel_SettlementCancel {
  __typename: "SettlementCancelResponse";
  ok: boolean;
  error: M_SettlementCancel_SettlementCancel_error | null;
  data: M_SettlementCancel_SettlementCancel_data | null;
}

export interface M_SettlementCancel {
  SettlementCancel: M_SettlementCancel_SettlementCancel;
}

export interface M_SettlementCancelVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_FeePolicyUpdate
// ====================================================

export interface M_FeePolicyUpdate_FeePolicyUpdate_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_FeePolicyUpdate_FeePolicyUpdate_data {
  __typename: "FeePolicy";
  _id: string;
  status: FeePolicyStatus;
}

export interface M_FeePolicyUpdate_FeePolicyUpdate {
  __typename: "FeePolicyUpdateResponse";
  ok: boolean;
  error: M_FeePolicyUpdate_FeePolicyUpdate_error | null;
  data: M_FeePolicyUpdate_FeePolicyUpdate_data | null;
}

export interface M_FeePolicyUpdate {
  FeePolicyUpdate: M_FeePolicyUpdate_FeePolicyUpdate;
}

export interface M_FeePolicyUpdateVariables {
  params: FeePolicyUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_SUPER_PageInfoCreate
// ====================================================

export interface M_SUPER_PageInfoCreate_PageInfoCreate_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_SUPER_PageInfoCreate_PageInfoCreate_data {
  __typename: "PageInfo";
  _id: string;
  key: string;
  value: any;
}

export interface M_SUPER_PageInfoCreate_PageInfoCreate {
  __typename: "PageInfoCreateResponse";
  ok: boolean;
  error: M_SUPER_PageInfoCreate_PageInfoCreate_error | null;
  data: M_SUPER_PageInfoCreate_PageInfoCreate_data | null;
}

export interface M_SUPER_PageInfoCreate {
  PageInfoCreate: M_SUPER_PageInfoCreate_PageInfoCreate;
}

export interface M_SUPER_PageInfoCreateVariables {
  params: PageInfoCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: M_SUPER_PageInfoUpdate
// ====================================================

export interface M_SUPER_PageInfoUpdate_PageInfoUpdate_error {
  __typename: "CustomError";
  location: string;
  severity: ERR_SEVERITY;
  code: ERR_CODE;
  message: string;
}

export interface M_SUPER_PageInfoUpdate_PageInfoUpdate_data {
  __typename: "PageInfo";
  _id: string;
  key: string;
  value: any;
}

export interface M_SUPER_PageInfoUpdate_PageInfoUpdate {
  __typename: "PageInfoUpdateResponse";
  ok: boolean;
  error: M_SUPER_PageInfoUpdate_PageInfoUpdate_error | null;
  data: M_SUPER_PageInfoUpdate_PageInfoUpdate_data | null;
}

export interface M_SUPER_PageInfoUpdate {
  PageInfoUpdate: M_SUPER_PageInfoUpdate_PageInfoUpdate;
}

export interface M_SUPER_PageInfoUpdateVariables {
  key: string;
  params: PageInfoUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Fsns
// ====================================================

export interface Fsns {
  __typename: "SNS";
  label: string;
  value: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Ffile
// ====================================================

export interface Ffile {
  __typename: "File";
  name: string;
  description: string | null;
  extension: string | null;
  fileType: string | null;
  uri: string;
  owner: string;
  x: number | null;
  y: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Fcategory
// ====================================================

export interface Fcategory {
  __typename: "Category";
  /**
   * 이름
   */
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FProfile
// ====================================================

export interface FProfile_profileImage {
  __typename: "File";
  name: string;
  description: string | null;
  extension: string | null;
  fileType: string | null;
  uri: string;
  owner: string;
  x: number | null;
  y: number | null;
}

export interface FProfile_categories {
  __typename: "Category";
  /**
   * 이름
   */
  name: string;
}

export interface FProfile_sns {
  __typename: "SNS";
  label: string;
  value: string;
}

export interface FProfile {
  __typename: "Profile";
  _id: string;
  createdAt: any;
  updatedAt: any;
  isDelete: boolean;
  /**
   * 인플루언서 이메일, 검색을 위해 필요
   */
  influencerEmail: string;
  /**
   * 이름
   */
  name: string;
  /**
   * 닉네임
   */
  nickName: string;
  /**
   * 프로필 이미지
   */
  profileImage: FProfile_profileImage | null;
  /**
   * 카테고리
   */
  categories: FProfile_categories[];
  /**
   * 개인 홈페이지 url
   */
  homepageURL: string;
  /**
   * 인스타그램 url
   */
  instagramURL: string;
  /**
   * 본인 소개
   */
  introduce: string;
  /**
   * 프로필 공개 여부
   */
  isOpen: boolean;
  price: number;
  /**
   * sns url
   */
  sns: FProfile_sns[];
  influencerCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Fuser
// ====================================================

export interface Fuser_profile_profileImage {
  __typename: "File";
  name: string;
  description: string | null;
  extension: string | null;
  fileType: string | null;
  uri: string;
  owner: string;
  x: number | null;
  y: number | null;
}

export interface Fuser_profile_categories {
  __typename: "Category";
  /**
   * 이름
   */
  name: string;
}

export interface Fuser_profile_sns {
  __typename: "SNS";
  label: string;
  value: string;
}

export interface Fuser_profile {
  __typename: "Profile";
  _id: string;
  createdAt: any;
  updatedAt: any;
  isDelete: boolean;
  /**
   * 인플루언서 이메일, 검색을 위해 필요
   */
  influencerEmail: string;
  /**
   * 이름
   */
  name: string;
  /**
   * 닉네임
   */
  nickName: string;
  /**
   * 프로필 이미지
   */
  profileImage: Fuser_profile_profileImage | null;
  /**
   * 카테고리
   */
  categories: Fuser_profile_categories[];
  /**
   * 개인 홈페이지 url
   */
  homepageURL: string;
  /**
   * 인스타그램 url
   */
  instagramURL: string;
  /**
   * 본인 소개
   */
  introduce: string;
  /**
   * 프로필 공개 여부
   */
  isOpen: boolean;
  price: number;
  /**
   * sns url
   */
  sns: Fuser_profile_sns[];
  influencerCode: string;
}

export interface Fuser {
  __typename: "User";
  _id: string;
  createdAt: any;
  updatedAt: any;
  isDelete: boolean;
  /**
   * 이메일
   */
  email: string;
  /**
   * 인플루언서 회원가입후 상태값
   */
  status: UserStatus;
  /**
   * 권한
   */
  role: UserRole;
  /**
   * 핸드폰번호
   */
  phoneNumber: string;
  /**
   * 닉네임
   */
  nickName: string;
  /**
   * 이름
   */
  name: string;
  profile: Fuser_profile | null;
  /**
   * 최근에 로그인한 날짜
   */
  lasteLogInDate: any | null;
  isVerifiedPhoneNumber: boolean;
  isVerifiedEmail: boolean;
  isVerifiedManager: boolean;
  acceptSms: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FStore
// ====================================================

export interface FStore_sosang_gong_in_profile_profileImage {
  __typename: "File";
  name: string;
  description: string | null;
  extension: string | null;
  fileType: string | null;
  uri: string;
  owner: string;
  x: number | null;
  y: number | null;
}

export interface FStore_sosang_gong_in_profile_categories {
  __typename: "Category";
  /**
   * 이름
   */
  name: string;
}

export interface FStore_sosang_gong_in_profile_sns {
  __typename: "SNS";
  label: string;
  value: string;
}

export interface FStore_sosang_gong_in_profile {
  __typename: "Profile";
  _id: string;
  createdAt: any;
  updatedAt: any;
  isDelete: boolean;
  /**
   * 인플루언서 이메일, 검색을 위해 필요
   */
  influencerEmail: string;
  /**
   * 이름
   */
  name: string;
  /**
   * 닉네임
   */
  nickName: string;
  /**
   * 프로필 이미지
   */
  profileImage: FStore_sosang_gong_in_profile_profileImage | null;
  /**
   * 카테고리
   */
  categories: FStore_sosang_gong_in_profile_categories[];
  /**
   * 개인 홈페이지 url
   */
  homepageURL: string;
  /**
   * 인스타그램 url
   */
  instagramURL: string;
  /**
   * 본인 소개
   */
  introduce: string;
  /**
   * 프로필 공개 여부
   */
  isOpen: boolean;
  price: number;
  /**
   * sns url
   */
  sns: FStore_sosang_gong_in_profile_sns[];
  influencerCode: string;
}

export interface FStore_sosang_gong_in {
  __typename: "User";
  _id: string;
  createdAt: any;
  updatedAt: any;
  isDelete: boolean;
  /**
   * 이메일
   */
  email: string;
  /**
   * 인플루언서 회원가입후 상태값
   */
  status: UserStatus;
  /**
   * 권한
   */
  role: UserRole;
  /**
   * 핸드폰번호
   */
  phoneNumber: string;
  /**
   * 닉네임
   */
  nickName: string;
  /**
   * 이름
   */
  name: string;
  profile: FStore_sosang_gong_in_profile | null;
  /**
   * 최근에 로그인한 날짜
   */
  lasteLogInDate: any | null;
  isVerifiedPhoneNumber: boolean;
  isVerifiedEmail: boolean;
  isVerifiedManager: boolean;
  acceptSms: boolean;
}

export interface FStore {
  __typename: "Store";
  /**
   * 소상공인
   */
  sosang_gong_in: FStore_sosang_gong_in;
  /**
   * 가게 ex) 식당, 도소매업
   */
  buisnessRange: string;
  /**
   * 상점명
   */
  storeName: string;
  /**
   * 상점 주소
   */
  address: string;
  /**
   * 상점 연락처
   */
  phoneNumber: string;
  /**
   * 상점 소개
   */
  introduce: string;
  /**
   * 네이버 스마트플레이스 url
   */
  naverSmartPlaceUrl: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Ftime
// ====================================================

export interface Ftime {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FSchedule
// ====================================================

export interface FSchedule_influencer_profile_profileImage {
  __typename: "File";
  name: string;
  description: string | null;
  extension: string | null;
  fileType: string | null;
  uri: string;
  owner: string;
  x: number | null;
  y: number | null;
}

export interface FSchedule_influencer_profile_categories {
  __typename: "Category";
  /**
   * 이름
   */
  name: string;
}

export interface FSchedule_influencer_profile_sns {
  __typename: "SNS";
  label: string;
  value: string;
}

export interface FSchedule_influencer_profile {
  __typename: "Profile";
  _id: string;
  createdAt: any;
  updatedAt: any;
  isDelete: boolean;
  /**
   * 인플루언서 이메일, 검색을 위해 필요
   */
  influencerEmail: string;
  /**
   * 이름
   */
  name: string;
  /**
   * 닉네임
   */
  nickName: string;
  /**
   * 프로필 이미지
   */
  profileImage: FSchedule_influencer_profile_profileImage | null;
  /**
   * 카테고리
   */
  categories: FSchedule_influencer_profile_categories[];
  /**
   * 개인 홈페이지 url
   */
  homepageURL: string;
  /**
   * 인스타그램 url
   */
  instagramURL: string;
  /**
   * 본인 소개
   */
  introduce: string;
  /**
   * 프로필 공개 여부
   */
  isOpen: boolean;
  price: number;
  /**
   * sns url
   */
  sns: FSchedule_influencer_profile_sns[];
  influencerCode: string;
}

export interface FSchedule_influencer {
  __typename: "User";
  _id: string;
  createdAt: any;
  updatedAt: any;
  isDelete: boolean;
  /**
   * 이메일
   */
  email: string;
  /**
   * 인플루언서 회원가입후 상태값
   */
  status: UserStatus;
  /**
   * 권한
   */
  role: UserRole;
  /**
   * 핸드폰번호
   */
  phoneNumber: string;
  /**
   * 닉네임
   */
  nickName: string;
  /**
   * 이름
   */
  name: string;
  profile: FSchedule_influencer_profile | null;
  /**
   * 최근에 로그인한 날짜
   */
  lasteLogInDate: any | null;
  isVerifiedPhoneNumber: boolean;
  isVerifiedEmail: boolean;
  isVerifiedManager: boolean;
  acceptSms: boolean;
}

export interface FSchedule_profile_profileImage {
  __typename: "File";
  name: string;
  description: string | null;
  extension: string | null;
  fileType: string | null;
  uri: string;
  owner: string;
  x: number | null;
  y: number | null;
}

export interface FSchedule_profile_categories {
  __typename: "Category";
  /**
   * 이름
   */
  name: string;
}

export interface FSchedule_profile_sns {
  __typename: "SNS";
  label: string;
  value: string;
}

export interface FSchedule_profile {
  __typename: "Profile";
  _id: string;
  createdAt: any;
  updatedAt: any;
  isDelete: boolean;
  /**
   * 인플루언서 이메일, 검색을 위해 필요
   */
  influencerEmail: string;
  /**
   * 이름
   */
  name: string;
  /**
   * 닉네임
   */
  nickName: string;
  /**
   * 프로필 이미지
   */
  profileImage: FSchedule_profile_profileImage | null;
  /**
   * 카테고리
   */
  categories: FSchedule_profile_categories[];
  /**
   * 개인 홈페이지 url
   */
  homepageURL: string;
  /**
   * 인스타그램 url
   */
  instagramURL: string;
  /**
   * 본인 소개
   */
  introduce: string;
  /**
   * 프로필 공개 여부
   */
  isOpen: boolean;
  price: number;
  /**
   * sns url
   */
  sns: FSchedule_profile_sns[];
  influencerCode: string;
}

export interface FSchedule_monday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface FSchedule_tuesday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface FSchedule_wednesday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface FSchedule_thursday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface FSchedule_friday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface FSchedule_saturday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface FSchedule_sunday {
  __typename: "Time";
  /**
   * 시작시간
   */
  start: number;
  /**
   * 종료시간
   */
  end: number;
}

export interface FSchedule {
  __typename: "Schedule";
  _id: string;
  createdAt: any;
  updatedAt: any;
  isDelete: boolean;
  influencer: FSchedule_influencer;
  /**
   * 인플루언서 이메일, 검색을 위해 필요
   */
  influencerEmail: string;
  profile: FSchedule_profile;
  /**
   * 요일막기, 무슨 요일에 장사안하는지 ex) [0, 3, 4]
   */
  day: number[];
  /**
   * 인플루언서가 장사안하는 날짜목록 ex) ['2020-10-10', '2020=10-11']
   */
  self: any[];
  /**
   * 소상공인이 예약한 날짜목록 ex) ['2020-10-10', '2020=10-11']
   */
  book: any[];
  /**
   * 월요일 시작 ~ 종료 시간
   */
  monday: FSchedule_monday;
  /**
   * 화요일 시작 ~ 종료 시간
   */
  tuesday: FSchedule_tuesday;
  /**
   * 수요일 시작 ~ 종료 시간
   */
  wednesday: FSchedule_wednesday;
  /**
   * 목요일 시작 ~ 종료 시간
   */
  thursday: FSchedule_thursday;
  /**
   * 금요일 시작 ~ 종료 시간
   */
  friday: FSchedule_friday;
  /**
   * 토요일 시작 ~ 종료 시간
   */
  saturday: FSchedule_saturday;
  /**
   * 일요일 시작 ~ 종료 시간
   */
  sunday: FSchedule_sunday;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * 질문 상태
 */
export enum AddtionalFeesStatus {
  DEFAULT = "DEFAULT",
  PERCNET = "PERCNET",
}

/**
 * BookingStatus
 */
export enum BookingStatus {
  CANCEL = "CANCEL",
  CANCEL_REQ = "CANCEL_REQ",
  COMPLETE = "COMPLETE",
  EXPIRED = "EXPIRED",
}

/**
 * 에러가 발생한 함수 위치
 */
export enum ERR_CODE {
  ALEADY_SAME_DATA = "ALEADY_SAME_DATA",
  AUTHORIZATION = "AUTHORIZATION",
  BACKEND_MESSAGE = "BACKEND_MESSAGE",
  BASKET_DOC_NOT_FOUND = "BASKET_DOC_NOT_FOUND",
  BOOKINGLITE_LOGIN_FAIL = "BOOKINGLITE_LOGIN_FAIL",
  BOOKING_ALEADY_EXIST = "BOOKING_ALEADY_EXIST",
  BOOKING_CANCEL_TWO_WEEK_OVER = "BOOKING_CANCEL_TWO_WEEK_OVER",
  BOOKING_DOC_NOT_FOUND = "BOOKING_DOC_NOT_FOUND",
  BOOKING_MEMBER_OVER = "BOOKING_MEMBER_OVER",
  BOOKING_PREVENTED = "BOOKING_PREVENTED",
  DELETED_DATA = "DELETED_DATA",
  DOC_ALEADY_EXIST = "DOC_ALEADY_EXIST",
  DOC_NOT_FOUND = "DOC_NOT_FOUND",
  DOC_RELATED_INVALID = "DOC_RELATED_INVALID",
  DUPLICATE_DATA = "DUPLICATE_DATA",
  EXPECTED_STATUS_NOT = "EXPECTED_STATUS_NOT",
  EXPECTED_VALUE_RANGE_NOT = "EXPECTED_VALUE_RANGE_NOT",
  EXPIRED = "EXPIRED",
  GENERAL = "GENERAL",
  INVALID_DOC = "INVALID_DOC",
  INVALID_EMAIL = "INVALID_EMAIL",
  INVALID_ENV = "INVALID_ENV",
  INVALID_PARAMS = "INVALID_PARAMS",
  INVALID_PASSWORD = "INVALID_PASSWORD",
  INVALID_PHONENUMBER = "INVALID_PHONENUMBER",
  INVALID_PRICE = "INVALID_PRICE",
  INVALID_PROFIE = "INVALID_PROFIE",
  NICKNAME_ALEADY_EXIST = "NICKNAME_ALEADY_EXIST",
  NOT_EQUAL_ROLE = "NOT_EQUAL_ROLE",
  NOT_INFLUENCER_OR_ADMIN = "NOT_INFLUENCER_OR_ADMIN",
  PASSWORD_CONFIRM_NOT_EQUAL = "PASSWORD_CONFIRM_NOT_EQUAL",
  PASSWORD_NOT_EQUAL = "PASSWORD_NOT_EQUAL",
  PASSWORD_RESET_CNT_OVER = "PASSWORD_RESET_CNT_OVER",
  PAY_TIME_OVER = "PAY_TIME_OVER",
  POPULATED_DOC_INVALID = "POPULATED_DOC_INVALID",
  POPULATED_PAYMENT_DOC_INVALID = "POPULATED_PAYMENT_DOC_INVALID",
  POPULATED_PRODUCT_DOC_INVALID = "POPULATED_PRODUCT_DOC_INVALID",
  POPULATED_PROFILE_DOC_INVALID = "POPULATED_PROFILE_DOC_INVALID",
  POPULATED_SCHEDULE_DOC_INVALID = "POPULATED_SCHEDULE_DOC_INVALID",
  POPULATED_STORE_DOC_INVALID = "POPULATED_STORE_DOC_INVALID",
  POPULATED_USER_DOC_INVALID = "POPULATED_USER_DOC_INVALID",
  PROFILE_DOC_NOT_FOUND = "PROFILE_DOC_NOT_FOUND",
  PROFILE_MAX_CNT_OVER = "PROFILE_MAX_CNT_OVER",
  SCHEDULE_DOC_NOT_FOUND = "SCHEDULE_DOC_NOT_FOUND",
  STORE_DOC_NOT_FOUND = "STORE_DOC_NOT_FOUND",
  SYSTEM_ERROR = "SYSTEM_ERROR",
  USER_ALEADY_EXIST = "USER_ALEADY_EXIST",
  USER_DELETED = "USER_DELETED",
  USER_DOC_NOT_FOUND = "USER_DOC_NOT_FOUND",
  USER_SIGNUP_NOT_ACCEPT = "USER_SIGNUP_NOT_ACCEPT",
  USER_SIGNUP_REJECT = "USER_SIGNUP_REJECT",
  USER_USAGE_STOP = "USER_USAGE_STOP",
  VERIFICATION_FAIL = "VERIFICATION_FAIL",
  VERIFICATION_NOT_FOUND = "VERIFICATION_NOT_FOUND",
  VERIFICATION_TIME_OVER = "VERIFICATION_TIME_OVER",
}

/**
 * 에러가 발생한 함수 위치
 */
export enum ERR_SEVERITY {
  ERROR = "ERROR",
  INFO = "INFO",
}

/**
 * 질문 상태
 */
export enum FeePolicyStatus {
  COPY = "COPY",
  ORIGINAL = "ORIGINAL",
}

export enum NotificationTriggerEvent {
  CANCEL_BOOKING_BOOKER = "CANCEL_BOOKING_BOOKER",
  CANCEL_BOOKING_SELLER = "CANCEL_BOOKING_SELLER",
  CANCEL_REQ_BOOKING_ADMIN = "CANCEL_REQ_BOOKING_ADMIN",
  CANCEL_REQ_WITHDRAWAL = "CANCEL_REQ_WITHDRAWAL",
  COMPLETE_BOOKING_BOOKER = "COMPLETE_BOOKING_BOOKER",
  COMPLETE_BOOKING_SELLER = "COMPLETE_BOOKING_SELLER",
  DELETE_USER_INFLUENCER = "DELETE_USER_INFLUENCER",
  SIGNUP_ACCEPT_INFLUENCER = "SIGNUP_ACCEPT_INFLUENCER",
  SIGNUP_REQ_INFLUENCER = "SIGNUP_REQ_INFLUENCER",
  START_USER_INFLUENCER = "START_USER_INFLUENCER",
  STOP_USER_INFLUENCER = "STOP_USER_INFLUENCER",
}

/**
 * 공제 대상
 */
export enum RequestStatus {
  ACCEPT = "ACCEPT",
  CANCEL = "CANCEL",
  CANCEL_REQ = "CANCEL_REQ",
  COMPLETE = "COMPLETE",
  DELETE = "DELETE",
  DETERMIN = "DETERMIN",
  EXPIRED = "EXPIRED",
  REJECT = "REJECT",
  REQUEST = "REQUEST",
  USAGE_START = "USAGE_START",
  USAGE_STOP = "USAGE_STOP",
  WITHDRAWAL = "WITHDRAWAL",
}

export enum SettlementStatus {
  COMPLETE = "COMPLETE",
  REQUEST = "REQUEST",
}

/**
 * 공제 대상
 */
export enum TargetStatus {
  BUSINESS = "BUSINESS",
  PERSONAL = "PERSONAL",
}

/**
 * 유저 역할!
 */
export enum UserRole {
  admin = "admin",
  anonymous = "anonymous",
  business = "business",
  influencer = "influencer",
}

/**
 * 유저 상태
 */
export enum UserStatus {
  ACCEPT = "ACCEPT",
  DELETE = "DELETE",
  READY = "READY",
  REJECT = "REJECT",
  USAGE_STOP = "USAGE_STOP",
}

/**
 * 인증 타겟 Enum
 */
export enum VerificationEvent {
  NotificationSenderAdd = "NotificationSenderAdd",
  SignInWithEmail = "SignInWithEmail",
  SignInWithPhone = "SignInWithPhone",
  SignInWtihGoogle = "SignInWtihGoogle",
  SignInWtihKakao = "SignInWtihKakao",
  SignUpWithBookingLite = "SignUpWithBookingLite",
  UserFindEmail = "UserFindEmail",
  UserResetPassword = "UserResetPassword",
  UserUpdateInfo = "UserUpdateInfo",
  UserVerifyEmail = "UserVerifyEmail",
  UserVerifyPhone = "UserVerifyPhone",
}

/**
 * 인증 타겟 Enum
 */
export enum VerificationTarget {
  BOOKING_LITE = "BOOKING_LITE",
  EMAIL = "EMAIL",
  PHONE = "PHONE",
}

/**
 * Auto generated sort type
 */
export enum _BasketItemSort {
  createdAt_asc = "createdAt_asc",
  createdAt_desc = "createdAt_desc",
  updatedAt_asc = "updatedAt_asc",
  updatedAt_desc = "updatedAt_desc",
}

/**
 * Auto generated sort type
 */
export enum _BookingSort {
  bookingDate_asc = "bookingDate_asc",
  bookingDate_desc = "bookingDate_desc",
  createdAt_asc = "createdAt_asc",
  createdAt_desc = "createdAt_desc",
  updatedAt_asc = "updatedAt_asc",
  updatedAt_desc = "updatedAt_desc",
}

/**
 * Auto generated sort type
 */
export enum _CategorySort {
  createdAt_asc = "createdAt_asc",
  createdAt_desc = "createdAt_desc",
  updatedAt_asc = "updatedAt_asc",
  updatedAt_desc = "updatedAt_desc",
}

/**
 * Auto generated sort type
 */
export enum _ITemplateSort {
  createdAt_asc = "createdAt_asc",
  createdAt_desc = "createdAt_desc",
  name_asc = "name_asc",
  name_desc = "name_desc",
  updatedAt_asc = "updatedAt_asc",
  updatedAt_desc = "updatedAt_desc",
}

/**
 * Auto generated sort type
 */
export enum _PageInfoSort {
  createdAt_asc = "createdAt_asc",
  createdAt_desc = "createdAt_desc",
  updatedAt_asc = "updatedAt_asc",
  updatedAt_desc = "updatedAt_desc",
}

/**
 * Auto generated sort type
 */
export enum _PriceSort {
  createdAt_asc = "createdAt_asc",
  createdAt_desc = "createdAt_desc",
  updatedAt_asc = "updatedAt_asc",
  updatedAt_desc = "updatedAt_desc",
}

/**
 * Auto generated sort type
 */
export enum _ProfileSort {
  acceptDate_asc = "acceptDate_asc",
  acceptDate_desc = "acceptDate_desc",
  createdAt_asc = "createdAt_asc",
  createdAt_desc = "createdAt_desc",
  randomIdx_asc = "randomIdx_asc",
  randomIdx_desc = "randomIdx_desc",
  updatedAt_asc = "updatedAt_asc",
  updatedAt_desc = "updatedAt_desc",
}

/**
 * Auto generated sort type
 */
export enum _SettlementSort {
  additionFeeSum_asc = "additionFeeSum_asc",
  additionFeeSum_desc = "additionFeeSum_desc",
  createdAt_asc = "createdAt_asc",
  createdAt_desc = "createdAt_desc",
  endDate_asc = "endDate_asc",
  endDate_desc = "endDate_desc",
  settlePrice_asc = "settlePrice_asc",
  settlePrice_desc = "settlePrice_desc",
  startDate_asc = "startDate_asc",
  startDate_desc = "startDate_desc",
  totalPrice_asc = "totalPrice_asc",
  totalPrice_desc = "totalPrice_desc",
  updatedAt_asc = "updatedAt_asc",
  updatedAt_desc = "updatedAt_desc",
}

/**
 * Auto generated sort type
 */
export enum _StoreSort {
  createdAt_asc = "createdAt_asc",
  createdAt_desc = "createdAt_desc",
  updatedAt_asc = "updatedAt_asc",
  updatedAt_desc = "updatedAt_desc",
}

/**
 * Auto generated sort type
 */
export enum _UserSort {
  createdAt_asc = "createdAt_asc",
  createdAt_desc = "createdAt_desc",
  updatedAt_asc = "updatedAt_asc",
  updatedAt_desc = "updatedAt_desc",
}

export interface AccountUpdateByMeInput {
  bankName: string;
  accNumber: string;
  bankOwnerName: string;
}

export interface AddtionalFeesUpdateInput {
  feeName: string;
  target: TargetStatus;
  type: AddtionalFeesStatus;
  feePercent?: number | null;
  fee?: number | null;
}

export interface BasketItemCreateInput {
  storeId: any;
  profileId: any;
  bookingDate: any;
  price: number;
  bookerMemo: string;
}

export interface BookingCancelInput {
  bookingId: string;
  reqStatus: BookingStatus;
  price: number;
  cancelMemo: string;
}

export interface BusinessSignInArgs {
  email: string;
  pw: string;
  hopeRole: UserRole;
  permanence?: boolean | null;
}

export interface BusinessSignInUpdateArgs {
  name: string;
  nickName: string;
  phoneNumber: string;
}

export interface CategoryCreateInput {
  name: string;
}

export interface CategoryUpdateInput {
  name: string;
}

export interface FeePolicyUpdateInput {
  addtionalFees?: AddtionalFeesUpdateInput[] | null;
}

export interface FileUpdateInput {
  name?: string | null;
  description?: string | null;
  extension?: string | null;
  fileType?: string | null;
  uri: string;
  owner?: string | null;
  x: number;
  y: number;
}

export interface GqlTagInput {
  key: string;
  value: string;
}

export interface NotificationTriggerCreateInput {
  sender?: string | null;
  event: NotificationTriggerEvent;
  isEnabled: boolean;
  tags?: GqlTagInput[] | null;
}

export interface PageInfoCreateInput {
  key: string;
  value: any;
}

export interface PageInfoUpdateInput {
  key?: string | null;
  value?: any | null;
}

export interface ProfileUpdateByMeInput {
  profileImage?: FileUpdateInput | null;
  categories?: string[] | null;
  unfavorList?: string | null;
  homepageURL?: string | null;
  sns?: snsInput[] | null;
  introduce?: string | null;
  isOpen?: boolean | null;
  phoneNumber?: string | null;
  region?: string | null;
}

export interface ReadyQueItemCreateForProfileInput {
  storeId: any;
  profileId: any;
  bookingDate: any;
  price: number;
  bookerMemo: string;
}

export interface RecommandProfileUpdateInput {
  isDisplay: boolean;
  minCount: number;
  maxCount: number;
  profiles: string[];
}

export interface ScheduleUpdateByMeInput {
  day: number[];
  self: any[];
  book: any[];
  monday: TimeUpdateInput;
  tuesday: TimeUpdateInput;
  wednesday: TimeUpdateInput;
  thursday: TimeUpdateInput;
  friday: TimeUpdateInput;
  saturday: TimeUpdateInput;
  sunday: TimeUpdateInput;
}

export interface SignUpInput {
  email: string;
  pw: string;
  name: string;
  nickName: string;
  phoneNumber: string;
  sns: snsInput[];
  introduce: string;
  bankName: string;
  accNumber: string;
  bankOwnerName: string;
  region: string;
}

export interface SmsTemplateUpdateInput {
  content?: string | null;
  name?: string | null;
  description?: string | null;
  trigger?: NotificationTriggerCreateInput | null;
  tags?: GqlTagInput[] | null;
}

export interface StoreCreateInput {
  storeName: string;
  buisnessRange: string;
  address: string;
  phoneNumber: string;
  introduce: string;
  naverSmartPlaceUrl: string;
}

export interface StoreUpdateInput {
  storeName: string;
  buisnessRange: string;
  address: string;
  phoneNumber: string;
  introduce: string;
  naverSmartPlaceUrl: string;
}

export interface TimeUpdateInput {
  start: number;
  end: number;
}

export interface _BasketItemFilter {
  AND?: _BasketItemFilter[] | null;
  OR?: _BasketItemFilter[] | null;
  _id_eq?: string | null;
  _id_not_eq?: string | null;
  _id_in?: string[] | null;
  createdAt_eq?: any | null;
  createdAt_not_eq?: any | null;
  createdAt_lte?: any | null;
  createdAt_lt?: any | null;
  createdAt_gte?: any | null;
  createdAt_gt?: any | null;
  updatedAt_eq?: any | null;
  updatedAt_not_eq?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_gte?: any | null;
  updatedAt_gt?: any | null;
}

export interface _BookingFilter {
  AND?: _BookingFilter[] | null;
  OR?: _BookingFilter[] | null;
  status_eq?: BookingStatus | null;
  status_not_eq?: BookingStatus | null;
  status_in?: BookingStatus[] | null;
  bookerName_eq?: string | null;
  bookerName_not_eq?: string | null;
  bookerName_contains?: string | null;
  bookerName_not_contains?: string | null;
  bookerName_in?: string[] | null;
  bookerName_not_in?: string[] | null;
  bookerEmail_eq?: string | null;
  bookerEmail_not_eq?: string | null;
  bookerEmail_contains?: string | null;
  bookerEmail_not_contains?: string | null;
  bookerEmail_in?: string[] | null;
  bookerEmail_not_in?: string[] | null;
  bookerPhoneNumber_eq?: string | null;
  bookerPhoneNumber_not_eq?: string | null;
  bookerPhoneNumber_contains?: string | null;
  bookerPhoneNumber_not_contains?: string | null;
  bookerPhoneNumber_in?: string[] | null;
  bookerPhoneNumber_not_in?: string[] | null;
  storeName_eq?: string | null;
  storeName_not_eq?: string | null;
  storeName_contains?: string | null;
  storeName_not_contains?: string | null;
  storeName_in?: string[] | null;
  storeName_not_in?: string[] | null;
  storePhoneNumber_eq?: string | null;
  storePhoneNumber_not_eq?: string | null;
  storePhoneNumber_contains?: string | null;
  storePhoneNumber_not_contains?: string | null;
  storePhoneNumber_in?: string[] | null;
  storePhoneNumber_not_in?: string[] | null;
  sellerName_eq?: string | null;
  sellerName_not_eq?: string | null;
  sellerName_contains?: string | null;
  sellerName_not_contains?: string | null;
  sellerName_in?: string[] | null;
  sellerName_not_in?: string[] | null;
  sellerNickName_eq?: string | null;
  sellerNickName_not_eq?: string | null;
  sellerNickName_contains?: string | null;
  sellerNickName_not_contains?: string | null;
  sellerNickName_in?: string[] | null;
  sellerNickName_not_in?: string[] | null;
  sellerEmail_eq?: string | null;
  sellerEmail_not_eq?: string | null;
  sellerEmail_contains?: string | null;
  sellerEmail_not_contains?: string | null;
  sellerEmail_in?: string[] | null;
  sellerEmail_not_in?: string[] | null;
  sellerPhoneNumber_eq?: string | null;
  sellerPhoneNumber_not_eq?: string | null;
  sellerPhoneNumber_contains?: string | null;
  sellerPhoneNumber_not_contains?: string | null;
  sellerPhoneNumber_in?: string[] | null;
  sellerPhoneNumber_not_in?: string[] | null;
  bookingDate_eq?: any | null;
  bookingDate_not_eq?: any | null;
  bookingDate_lte?: any | null;
  bookingDate_lt?: any | null;
  bookingDate_gte?: any | null;
  bookingDate_gt?: any | null;
  _id_eq?: string | null;
  _id_not_eq?: string | null;
  _id_in?: string[] | null;
  createdAt_eq?: any | null;
  createdAt_not_eq?: any | null;
  createdAt_lte?: any | null;
  createdAt_lt?: any | null;
  createdAt_gte?: any | null;
  createdAt_gt?: any | null;
  updatedAt_eq?: any | null;
  updatedAt_not_eq?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_gte?: any | null;
  updatedAt_gt?: any | null;
}

export interface _CategoryFilter {
  AND?: _CategoryFilter[] | null;
  OR?: _CategoryFilter[] | null;
  _id_eq?: string | null;
  _id_not_eq?: string | null;
  _id_in?: string[] | null;
  createdAt_eq?: any | null;
  createdAt_not_eq?: any | null;
  createdAt_lte?: any | null;
  createdAt_lt?: any | null;
  createdAt_gte?: any | null;
  createdAt_gt?: any | null;
  updatedAt_eq?: any | null;
  updatedAt_not_eq?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_gte?: any | null;
  updatedAt_gt?: any | null;
}

export interface _ITemplateFilter {
  AND?: _ITemplateFilter[] | null;
  OR?: _ITemplateFilter[] | null;
  _id_eq?: string | null;
  _id_not_eq?: string | null;
  _id_in?: string[] | null;
  createdAt_eq?: any | null;
  createdAt_not_eq?: any | null;
  createdAt_lte?: any | null;
  createdAt_lt?: any | null;
  createdAt_gte?: any | null;
  createdAt_gt?: any | null;
  updatedAt_eq?: any | null;
  updatedAt_not_eq?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_gte?: any | null;
  updatedAt_gt?: any | null;
}

export interface _PageInfoFilter {
  AND?: _PageInfoFilter[] | null;
  OR?: _PageInfoFilter[] | null;
  _id_eq?: string | null;
  _id_not_eq?: string | null;
  _id_in?: string[] | null;
  createdAt_eq?: any | null;
  createdAt_not_eq?: any | null;
  createdAt_lte?: any | null;
  createdAt_lt?: any | null;
  createdAt_gte?: any | null;
  createdAt_gt?: any | null;
  updatedAt_eq?: any | null;
  updatedAt_not_eq?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_gte?: any | null;
  updatedAt_gt?: any | null;
}

export interface _PriceFilter {
  AND?: _PriceFilter[] | null;
  OR?: _PriceFilter[] | null;
  _id_eq?: string | null;
  _id_not_eq?: string | null;
  _id_in?: string[] | null;
  createdAt_eq?: any | null;
  createdAt_not_eq?: any | null;
  createdAt_lte?: any | null;
  createdAt_lt?: any | null;
  createdAt_gte?: any | null;
  createdAt_gt?: any | null;
  updatedAt_eq?: any | null;
  updatedAt_not_eq?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_gte?: any | null;
  updatedAt_gt?: any | null;
}

export interface _ProfileFilter {
  AND?: _ProfileFilter[] | null;
  OR?: _ProfileFilter[] | null;
  influencerEmail_eq?: string | null;
  influencerEmail_not_eq?: string | null;
  influencerEmail_contains?: string | null;
  influencerEmail_not_contains?: string | null;
  influencerEmail_in?: string[] | null;
  influencerEmail_not_in?: string[] | null;
  name_eq?: string | null;
  name_not_eq?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  nickName_eq?: string | null;
  nickName_not_eq?: string | null;
  nickName_contains?: string | null;
  nickName_not_contains?: string | null;
  nickName_in?: string[] | null;
  nickName_not_in?: string[] | null;
  categories_eq?: string | null;
  categories_not_eq?: string | null;
  categories_in?: string[] | null;
  categories_contains?: string | null;
  isOpen_eq?: boolean | null;
  isOpen_not_eq?: boolean | null;
  isStopByAdmin_eq?: boolean | null;
  isStopByAdmin_not_eq?: boolean | null;
  _id_eq?: string | null;
  _id_not_eq?: string | null;
  _id_in?: string[] | null;
  createdAt_eq?: any | null;
  createdAt_not_eq?: any | null;
  createdAt_lte?: any | null;
  createdAt_lt?: any | null;
  createdAt_gte?: any | null;
  createdAt_gt?: any | null;
  updatedAt_eq?: any | null;
  updatedAt_not_eq?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_gte?: any | null;
  updatedAt_gt?: any | null;
}

export interface _SettlementFilter {
  AND?: _SettlementFilter[] | null;
  OR?: _SettlementFilter[] | null;
  status_eq?: SettlementStatus | null;
  status_not_eq?: SettlementStatus | null;
  status_in?: SettlementStatus[] | null;
  name_eq?: string | null;
  name_not_eq?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  nickName_eq?: string | null;
  nickName_not_eq?: string | null;
  nickName_contains?: string | null;
  nickName_not_contains?: string | null;
  nickName_in?: string[] | null;
  nickName_not_in?: string[] | null;
  startDate_eq?: any | null;
  startDate_not_eq?: any | null;
  startDate_lte?: any | null;
  startDate_lt?: any | null;
  startDate_gte?: any | null;
  startDate_gt?: any | null;
  endDate_eq?: any | null;
  endDate_not_eq?: any | null;
  endDate_lte?: any | null;
  endDate_lt?: any | null;
  endDate_gte?: any | null;
  endDate_gt?: any | null;
  _id_eq?: string | null;
  _id_not_eq?: string | null;
  _id_in?: string[] | null;
  createdAt_eq?: any | null;
  createdAt_not_eq?: any | null;
  createdAt_lte?: any | null;
  createdAt_lt?: any | null;
  createdAt_gte?: any | null;
  createdAt_gt?: any | null;
  updatedAt_eq?: any | null;
  updatedAt_not_eq?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_gte?: any | null;
  updatedAt_gt?: any | null;
}

export interface _StoreFilter {
  AND?: _StoreFilter[] | null;
  OR?: _StoreFilter[] | null;
  storeName_eq?: string | null;
  storeName_not_eq?: string | null;
  storeName_contains?: string | null;
  storeName_not_contains?: string | null;
  storeName_in?: string[] | null;
  storeName_not_in?: string[] | null;
  _id_eq?: string | null;
  _id_not_eq?: string | null;
  _id_in?: string[] | null;
  createdAt_eq?: any | null;
  createdAt_not_eq?: any | null;
  createdAt_lte?: any | null;
  createdAt_lt?: any | null;
  createdAt_gte?: any | null;
  createdAt_gt?: any | null;
  updatedAt_eq?: any | null;
  updatedAt_not_eq?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_gte?: any | null;
  updatedAt_gt?: any | null;
}

export interface _UserFilter {
  AND?: _UserFilter[] | null;
  OR?: _UserFilter[] | null;
  status_eq?: UserStatus | null;
  status_not_eq?: UserStatus | null;
  status_in?: UserStatus[] | null;
  role_eq?: UserRole | null;
  role_not_eq?: UserRole | null;
  phoneNumber_eq?: string | null;
  phoneNumber_not_eq?: string | null;
  phoneNumber_contains?: string | null;
  phoneNumber_not_contains?: string | null;
  phoneNumber_in?: string[] | null;
  phoneNumber_not_in?: string[] | null;
  nickName_eq?: string | null;
  nickName_not_eq?: string | null;
  nickName_contains?: string | null;
  nickName_not_contains?: string | null;
  nickName_in?: string[] | null;
  nickName_not_in?: string[] | null;
  name_eq?: string | null;
  name_not_eq?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  isVerifiedManager_eq?: boolean | null;
  isVerifiedManager_not_eq?: boolean | null;
  _id_eq?: string | null;
  _id_not_eq?: string | null;
  _id_in?: string[] | null;
  createdAt_eq?: any | null;
  createdAt_not_eq?: any | null;
  createdAt_lte?: any | null;
  createdAt_lt?: any | null;
  createdAt_gte?: any | null;
  createdAt_gt?: any | null;
  updatedAt_eq?: any | null;
  updatedAt_not_eq?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_gte?: any | null;
  updatedAt_gt?: any | null;
}

export interface pageInput {
  page: number;
  cntPerPage: number;
}

export interface snsInput {
  label: string;
  value: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
