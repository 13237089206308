import { FProfile } from "../apollo/types";

export type TDays_Keys = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
export type TDateFormatHours = "withHour";
export type TDateInfoFormat = "slash";

export type TSearchList = {
  name?: string | undefined;
  category?: string[] | undefined;
};

export enum TToken_Role {
  business = "business",
  influencer = "influencer",
  admin = "admin",
  super = "super",
}

export type TPageInfoSearch<SEARCHSTATE> = {
  name: any;
  category: any;
  date_start: any;
  date_end: any;
  sort: any;
  state: SEARCHSTATE;
};

export type TPageInfo<DATALIST, SEARCH> = {
  datalist: DATALIST;
  search: SEARCH;
  pagination: any;
};

export type TPaginationMove = "next" | "last" | "prev" | "first";

export type TModalState = {
  modal: string;
  active: boolean;
};

// T: 출력할 리스트 타입
// Q: 출력할 타입
export type TMapper<T, Q> = (listInfo: T) => {
  [key in keyof Q]?: {
    render(): JSX.Element;
  };
};
