import dayjs from "dayjs";
import { TDateFormatHours, TDateInfoFormat, TPaginationMove, TToken_Role } from "type/types";
import { months_kor } from "utils/calerdarInfo";
import { TSNS } from "../type/influencer/influencerTypes";
import { no_image, token_role } from "./commonVariables";

/* ::::::::::::::::::::::::::::::::::::

    Vailidation / Check

:::::::::::::::::::::::::::::::::::: */

export const checkIsAdmin = (checkrole: string | null) => {
  if (localStorage.getItem(token_role) === TToken_Role.super) {
    return true;
  }
  if (localStorage.getItem(token_role) === TToken_Role.admin) {
    return true;
  }
  return false;
};

export const chkIsArray = (arr: any) => {
  return Array.isArray(arr);
};

export const chkIsDate = (date: any) => {
  return date instanceof Date;
};

export const validateEmail = (email: any) => {
  let re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return re.test(email);
};

export const validatePassword = (password: string, repassword: string) => {
  return password === repassword;
};

export const validatePwEmpty = (pw: any) => {
  if (pw.length < 1) {
    return false;
  }
  return true;
};

export const validatePwForm = (pw: any) => {
  // 최소 8 자, 최소 하나의 문자 및 하나의 숫자 :
  return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&]?)[A-Za-z\d$@$!%*#?&]{8,}$/.test(pw);
};

export const validateOnlyNumber = (num: any) => {
  return /^\d+$/.test(num);
};

export const validatePhoneNum = (num: any) => {
  var number = num.split("-").join("");
  var regPhone = /^((01[1|6|7|8|9])[0-9]+[0-9]{6,7})|(010[0-9][0-9]{7})$/;
  return regPhone.test(number);
};

export const validateFullUrl = (url: string) => {
  return url.startsWith("https://");
};

export const validateSNS = (snsList: TSNS[]) => {
  return !snsList.every((sns) => validateFullUrl(sns.value));
};

export const chkHasHttp = (str: string) => {
  return str.indexOf("http") > -1;
};

export const chkLengthValid = (val: any, length?: number) => {
  if (length) {
    if (val.length >= length) {
      return true;
    }
    if (val.length < length) {
      return false;
    }
  }

  if (!length) {
    if (val.length > 0) {
      return true;
    }
    if (val.length === 0) {
      return false;
    }
  }
};

export const isPastDate = (selectedDay: number, today: number) => {
  // 비교를 위한 time은 timestamp형식으로 넘길 것.
  return selectedDay < today ? true : false;
};

export const chkPastDayNumber = (date: Date) => {
  const chkDay = dayjs(date);
  const pastDayNum = chkDay.diff(new Date(), "day");
  return pastDayNum;
};

/* ::::::::::::::::::::::::::::::::::::

    Object

:::::::::::::::::::::::::::::::::::: */

export const deepClone = (obj: any) => {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }
  let result: any = Array.isArray(obj) ? [] : {};
  for (let key of Object.keys(obj)) {
    result[key] = deepClone(obj[key]);
  }
  return result;
};

/* ::::::::::::::::::::::::::::::::::::

    Array

:::::::::::::::::::::::::::::::::::: */

export const arrayElementRemove = (arr: any, remove: any) => {
  return arr.filter(function (itemList: any) {
    return itemList !== remove;
  });
};

export const arrayIncludes = (arr: any, search: any) => {
  return arr.includes(search);
};

export const arrayInsertComma = (arr: string[]) => {
  return arr.map(function (arrList: any, index: any) {
    return arr.length - 1 === index ? arrList : arrList + ", ";
  });
};

/* ::::::::::::::::::::::::::::::::::::

    String

:::::::::::::::::::::::::::::::::::: */

export const utilTruncation = (content: any, num: number, dots?: boolean) => {
  if (content.length <= num) {
    return content;
  }
  return content.substring(0, num) + `${dots ? "..." : ""}`;
};

export const utilReturnHTML = (ele: any) => {
  return ele.innerHTML;
};

export const removeWhiteSapce = (str: string) => {
  return str.replace(/\s/g, "");
};

export const removeHTMLTags = (target: any) => {
  return target.replace(/(<([^>]+)>)/gi, "");
};

/* ::::::::::::::::::::::::::::::::::::

    Date

:::::::::::::::::::::::::::::::::::: */

export const getTomorrow = () => {
  const today = new Date();
  const tomorrow = new Date();
  return new Date(tomorrow.setDate(today.getDate() + 1));
};

export const getYesterday = () => {
  const today = new Date();
  const yesterday = new Date();
  return new Date(yesterday.setDate(today.getDate() - 1));
};

export const setDateHours = (date: Date, hour: number) => {
  const new_date = date.setHours(hour);
  return new Date(new_date);
};

export const startDateSetHours = (date: Date | undefined) => {
  if (date === undefined) {
    return undefined;
  }
  const new_date = date.setHours(1, 59);
  return new Date(new_date);
};

export const endDateSetHours = (date: Date | undefined) => {
  if (date === undefined) {
    return undefined;
  }
  const new_date = date.setHours(23, 59);
  return new Date(new_date);
};

/* ::::::::::::::::::::::::::::::::::::

    Convert

:::::::::::::::::::::::::::::::::::: */

export const HoursToInterger = (time: any) => {
  let hour_integer = time.substring(0, 2);
  return parseInt(hour_integer);
};

export const TimeConverter = (time: string | number) => {
  if (typeof time === "string") {
    let time_string = time.substring(0, 2);
    if (parseInt(time_string) === 24) {
      const time_afternoon = parseInt(time_string) - 12;
      return `오전 ${time_afternoon}시`;
    }
    if (parseInt(time_string) < 12) {
      return `오전 ${time_string}시`;
    }
    if (parseInt(time_string) >= 12) {
      const time_afternoon = parseInt(time_string) - 12;
      return `오후 ${time_afternoon === 0 ? 12 : time_afternoon}시`;
    }
  }

  if (typeof time === "number") {
    if (time === 24) {
      const time_afternoon = time - 12;
      return `오전 ${time_afternoon}시`;
    }
    if (time < 12) {
      return `오전 ${time}시`;
    }
    if (time >= 12) {
      const time_afternoon = time - 12;
      return `오후 ${time_afternoon === 0 ? 12 : time_afternoon}시`;
    }
  }
};

export const getCategoryName = (categories: any) => {
  const isArray = chkIsArray(categories);
  if (!isArray) {
    return ["Wrong Type"];
  }
  return categories.map(function (category: any) {
    return category.name;
  });
};

/* ::::::::::::::::::::::::::::::::::::

    Format

:::::::::::::::::::::::::::::::::::: */

export const monthFormat = (month: number) => {
  return months_kor[month];
};

export const priceFormat = (price: number) => {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const numberToTimeFormat = (time: number) => {
  if (time < 10) {
    return `0${time}:00`;
  } else {
    return `${time}:00`;
  }
};

export const selectedScheduleInfo = (year: number, month: number, day: number, hour?: number) => {
  if (typeof hour !== undefined) {
    return `${year}년 ${monthFormat(month)} ${day}일 ${TimeConverter(hour!)}`;
  }

  return `${year}년 ${monthFormat(month)} ${day}일`;
};

export const stringArrToDate = (target: string[]) => {
  return target.map(function (targetList) {
    return new Date(targetList);
  });
};

export const dateInfoFormat = (date: Date, hours?: TDateFormatHours) => {
  let resultDate = "";
  const returnDate = new Date(date);
  const _year = returnDate.getFullYear();
  const _month = returnDate.getMonth();
  const _date = returnDate.getDate();
  resultDate = `${_year}년 ${monthFormat(_month)} ${_date}일`;
  if (hours) {
    const _hours = returnDate.getHours();
    resultDate = `${_year}년 ${monthFormat(_month)} ${_date}일 ${TimeConverter(_hours)}`;
  }
  return resultDate;
};

export const dateInfoFormatCustom = (date: Date, format: any, hours?: TDateFormatHours) => {
  let resultDate = "";
  const returnDate = new Date(date);
  const _year = returnDate.getFullYear();
  const _month = returnDate.getMonth() + 1;
  const _date = returnDate.getDate();
  resultDate = `${_year}${format}${_month}${format}${_date}`;
  if (hours) {
    const _hours = returnDate.getHours();
    resultDate = `${_year}${format}${_month}${format}${_date}${format} ${TimeConverter(_hours)}`;
  }
  return resultDate;
};

export const getTodayMidnight = () => {
  const t_midnight = new Date();
  return new Date(t_midnight.setHours(0));
};

export const displayCategoryName = (category: any, className: string) => {
  const isArray = chkIsArray(category);
  if (!isArray) {
    return "Wrong Format";
  }
  if (category.length === 0) {
    return (
      <span key={`category_no`} className={`${className}`}>
        분야없음
      </span>
    );
  }
  return category.map(function (categoryList: any, index: any) {
    return (
      <span key={`category_${index}`} className={`${className}`}>
        {categoryList}
      </span>
    );
  });
};

export const displayCategoryNameSimple = (category: any) => {
  const isArray = chkIsArray(category);
  if (!isArray) {
    return "Wrong Format";
  }
  if (category.length === 0) {
    return "분야없음";
  }
  return category.map(function (arrList: any, index: any) {
    return category.length - 1 === index ? arrList : arrList + ", ";
  });
};

export const nullReturnEmptyString = (target: any) => {
  if (target === null) {
    return "";
  }
  return target;
};

/* ::::::::::::::::::::::::::::::::::::

    Processor

:::::::::::::::::::::::::::::::::::: */

export const ImageProcess = (displayData: any, calledFrom: string) => {
  // console.log('from : ' + calledFrom);
  if (!displayData.image) {
    return no_image;
  }

  if (displayData.image) {
    if (displayData.image.uri === "") {
      return no_image;
    }
  }
  return displayData.image.uri;
};

export const getImagePosition = (displayData: any, calledFrom: string) => {
  // console.log('from : ' + calledFrom);
  if (!displayData.image) {
    return { x: 50, y: 50 };
  }
  if (displayData.image) {
    if (displayData.image.uri === "") {
      return { x: 50, y: 50 };
    }
  }
  return { x: displayData.image.x, y: displayData.image.y };
};

export const ExtraDataProcess = (target: any) => {
  if (target.length > 0) {
    return target[0].value;
  }
  return undefined;
};

/* ::::::::::::::::::::::::::::::::::::

    Pagination

:::::::::::::::::::::::::::::::::::: */

export const pagiInfoProcess = (data: any, pagination: any) => {
  const pageTotalNum = Math.ceil(data / pagination.dataListNum);
  const blockTotalNum = Math.ceil(pageTotalNum / pagination.blockDisplayNum);
  const blockCurrent = Math.ceil(pagination.current / pagination.blockDisplayNum);
  let firstPage = blockCurrent * pagination.blockDisplayNum - (pagination.blockDisplayNum - 1);
  let endPage = blockCurrent * pagination.blockDisplayNum;

  if (firstPage <= 1) {
    firstPage = 1;
  }

  if (endPage >= pageTotalNum) {
    endPage = pageTotalNum;
  }

  let pagiInfo = {
    current: pagination.current,
    dataListNum: pagination.dataListNum,
    blockDisplayNum: pagination.blockDisplayNum,
    pageTotalNum: pageTotalNum,
    blockTotalNum: blockTotalNum,
    blockCurrent: blockCurrent,
    firstPage: firstPage,
    endPage: endPage,
  };

  // console.log(' :::::: Page Info Processed :::::: ');
  // console.log(pagiInfo);

  return pagiInfo;
};

export const paginationMoveHandler = (move: any, pagiInfo: any, pagination: any) => {
  switch (move) {
    case "next":
      return pagiInfo.endPage + 1;

    case "last":
      return pagiInfo.pageTotalNum;

    case "prev":
      return pagiInfo.firstPage - pagination.blockDisplayNum;

    case "first":
      return 1;

    default:
  }
};

export const isProduction = () => process.env.NODE_ENV === "production";
