import React, { useState } from "react";
import { Link } from "react-router-dom";
import { svg_cancel_bold, svg_cancel_light, svg_menu } from "assets/svg";
import { TextraDataValue } from "type/super/superTypes";
import { token_name } from "utils/commonVariables";
interface IProps {
  title: string;
  menuList: any;
  extraDataValue: TextraDataValue;
}

const Sidebar: React.FC<IProps> = ({ title, menuList, extraDataValue }) => {
  const [mbMenuOpen, setMbMenuOpen] = useState(false);
  const currentNav = window.location.pathname;

  const handleLogOut = () => {
    const chkConfirm = window.confirm("로그아웃 하시겠습니까?");
    if (chkConfirm) {
      localStorage.setItem(token_name, ``);
      window.location.replace("/");
    }
  };

  const displaySidebarTitle = (extraData: TextraDataValue) => {
    if (!extraData) {
      return "PROVIDED BY";
    }
    return extraData!.sidebar_title;
  };

  const displaySidebarSubtitle = (extraData: TextraDataValue) => {
    if (!extraData) {
      return "대한민국 1등<div>클라우드·핀테크 기반</div><div>예약솔루션 '잔다'</div>";
    }
    return extraData!.sidebar_subtitle;
  };

  // console.log(localStorage.getItem('infmarket_token'));

  return (
    <>
      <div className="mbmenu">
        <div
          className={`mbmenu__btn ${mbMenuOpen ? "on" : ""}`}
          onClick={() => {
            setMbMenuOpen(true);
          }}
          dangerouslySetInnerHTML={{ __html: svg_menu }}
        ></div>
        <div
          className={`mbmenu__close ${mbMenuOpen ? "on" : ""}`}
          onClick={() => {
            setMbMenuOpen(false);
          }}
          dangerouslySetInnerHTML={{ __html: svg_cancel_bold }}
        ></div>
        <div className={`mbmenu__bg ${mbMenuOpen ? "on" : ""}`}></div>
        <div className={`mbmenu__title ${mbMenuOpen ? "on" : ""}`}>{title}</div>
        <div className={`mbmenu__logout ${mbMenuOpen ? "on" : ""}`} onClick={handleLogOut}>
          <Link to={`/`}>로그아웃</Link>
        </div>
        <div className={`mbmenu__provider ${mbMenuOpen ? "on" : ""}`}>
          <div dangerouslySetInnerHTML={{ __html: displaySidebarTitle(extraDataValue) }}></div>
          <div dangerouslySetInnerHTML={{ __html: displaySidebarSubtitle(extraDataValue) }}></div>
        </div>
        <div className={`mbmenulist ${mbMenuOpen ? "on" : ""}`}>
          <ul className="mbmenu__linkwrap">
            {menuList.map(function (menu: any, index: number) {
              return (
                <li key={`sidebar_${index}`}>
                  <Link to={`${menu.link}`} className={`mbmenu__link ${currentNav == menu.link && "on"}`}>
                    {menu.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="sidebar">
        <div>
          <div className="sidebar__titlewrap">
            <div className="sidebar__title">{title}</div>
          </div>

          <ul className="sidebar__linkwrap cus_scroll">
            {menuList.map(function (menu: any, index: number) {
              return (
                <li key={`sidebar_${index}`}>
                  <Link to={`${menu.link}`} className={`sidebar__link ${currentNav == menu.link && "on"}`}>
                    {menu.name}
                  </Link>
                </li>
              );
            })}
            <li key={`sidebar_${menuList.length}`}>
              <div className={`sidebar__link sidebar__logout `} onClick={handleLogOut}>
                로그아웃
              </div>
            </li>
          </ul>
        </div>
        <div className="sidebar__intro">
          <p
            className="sidebar__intromain"
            dangerouslySetInnerHTML={{ __html: displaySidebarTitle(extraDataValue) }}
          ></p>
          <p
            className="sidebar__introsub"
            dangerouslySetInnerHTML={{ __html: displaySidebarSubtitle(extraDataValue) }}
          ></p>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
