export const token_name = "infmarket_token";
export const token_role = "infmarket_role";
export const token_prefix = "Bearer";
export const nodata_text = "데이터가 존재하지 않습니다";
export const no_image = "/image/common/no_image.jpg";
export const dayList:any = {
    monday:"월요일",
    tuesday:"화요일",
    wednesday:"수요일",
    thursday:"목요일 ",
    friday:"금요일",
    saturday:"토요일",
    sunday:"일요일"
}