import React, { useEffect, useState } from "react";
import { ModalContext } from "context/ContextInfluencer";
import Sidebar from "component/common/sidebar/Sidebar";
import "styles/super/super.scss";
import { SUPER_EXTRAINFO } from "apollo/gql/query";
import { Q_SUPER_PageInfoList, _PageInfoSort } from "apollo/types";
import { useQuery } from "@apollo/client";
import GraphError from "component/common/error/GraphError";
import { ExtraDataProcess } from "utils/utils";
import { TextraDataValue } from "type/super/superTypes";
import RoleAuthError from "component/common/error/RoleAuthError";
import { token_role } from "utils/commonVariables";
import { TToken_Role } from "type/types";

console.log("Version : 1.1");

const Super: React.FC = ({ children }: any) => {
  const [modalState, setModalState] = useState({
    modal: "",
    active: false,
  });

  /* ::::: API CALL - 여분필드 리스트 ::::: */
  const {
    loading: exraLoading,
    error: extraError,
    data: extraData,
  } = useQuery<Q_SUPER_PageInfoList>(SUPER_EXTRAINFO, {
    variables: {
      sort: [_PageInfoSort.createdAt_desc],
      pageInput: {
        page: 1,
        cntPerPage: 10,
      },
    },

    fetchPolicy: "network-only",
  });

  const menuList = [{ name: "환경설정", link: "/super/setting" }];

  if (localStorage.getItem(token_role) !== TToken_Role.super) {
    return <RoleAuthError role={localStorage.getItem(token_role)!} />;
  }
  if (exraLoading) return null;
  if (extraError) return <GraphError err={extraError.message} page="super" />;
  const extraDataValue: TextraDataValue = ExtraDataProcess(extraData?.PageInfoList.data);

  // console.log('admin common');
  // console.log(localStorage.getItem(token_role));

  return (
    <ModalContext.Provider value={{ modalState, setModalState }}>
      <div className={`super`}>
        <div className={`bgfilter ${modalState.active && "on"}`}></div>
        <Sidebar title={"최고관리자"} menuList={menuList} extraDataValue={extraDataValue} />
        {children}
      </div>
    </ModalContext.Provider>
  );
};

export default Super;
