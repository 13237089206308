import { gql } from "@apollo/client";
import { F_SNS } from "./fragment";

/* :::::::::::::::::::::::::::::::::::::::::: 

    Query - Common

::::::::::::::::::::::::::::::::::::::::: */

export const CHK_LOGGEDIN = gql`
  query CheckLoggedIn {
    CheckLoggedIn {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        isLoggedIn
        role
      }
    }
  }
`;

export const CATEGORY_LIST = gql`
  query CategoryList($sort: [_CategorySort!], $filter: _CategoryFilter, $pageInput: pageInput!) {
    CategoryList(sort: $sort, filter: $filter, pageInput: $pageInput) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        name
      }
    }
  }
`;

export const PRICE_LIST = gql`
  query Q_PriceList($sort: [_PriceSort!], $filter: _PriceFilter, $pageInput: pageInput!) {
    PriceList(sort: $sort, filter: $filter, pageInput: $pageInput) {
      ok
      error {
        location
        severity
        code
        message
      }
      page {
        page
        cntPerPage
        totalPageSize
        totalCount
      }
      data {
        _id
        price
      }
    }
  }
`;

/* :::::::::::::::::::::::::::::::::::::::::: 

    Query - Influencer 

::::::::::::::::::::::::::::::::::::::::: */

export const INFLUENCER_CODE_FIND = gql`
  query InfluencerCodeFind {
    InfluencerCodeFind {
      ok
      data
      error {
        location
        severity
        code
        message
      }
    }
  }
`;

export const INFLUENCER_REQUEST_LISt = gql`
  query Q_Inf_BookingList($sort: [_BookingSort!], $filter: _BookingFilter, $pageInput: pageInput!) {
    BookingList(sort: $sort, filter: $filter, pageInput: $pageInput) {
      ok
      error {
        location
        severity
        code
        message
      }
      page {
        page
        cntPerPage
        totalPageSize
        totalCount
      }
      data {
        _id
        status
        bookerName
        bookerNickName
        bookingDate
        bookerMemo
        storeName
        storeAddress
        storeIntroduce
        storeNaverSmartPlaceUrl
        status
        latestRequestHistory {
          reqType
          reason
        }
      }
    }
  }
`;

export const INFLUENCER_SIGNIN = gql`
  query GraphSignIn($email: String!, $pw: String!, $hopeRole: UserRole!, $permanence: Boolean) {
    SignIn(email: $email, pw: $pw, hopeRole: $hopeRole, permanence: $permanence) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        token
      }
    }
  }
`;

export const INFLUENCER_SCHEDULE = gql`
  query ScheduleFindByMe {
    ScheduleFindByMe {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        day
        self
        book
        influencer {
          _id
        }
        monday {
          start
          end
        }
        tuesday {
          start
          end
        }
        wednesday {
          start
          end
        }
        thursday {
          start
          end
        }
        friday {
          start
          end
        }
        saturday {
          start
          end
        }
        sunday {
          start
          end
        }
      }
    }
  }
`;

export const INFLUENCER_SCHEDULE_HOURS = gql`
  query ScheduleFindByMeHours {
    ScheduleFindByMe {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        day
        self
        book
        influencer {
          _id
        }
        monday {
          start
          end
        }
        tuesday {
          start
          end
        }
        wednesday {
          start
          end
        }
        thursday {
          start
          end
        }
        friday {
          start
          end
        }
        saturday {
          start
          end
        }
        sunday {
          start
          end
        }
      }
    }
  }
`;

export const INFLUENCER_PROFILE = gql`
  query ProfileFindByMe {
    ProfileFindByMe {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        isOpen
        isDelete
        name
        influencerEmail
        phoneNumber
        unfavorList
        profileImage {
          _id
          name
          description
          extension
          fileType
          uri
          owner
          x
          y
        }
        introduce
        region
        sns {
          label
          value
        }
        homepageURL
        categories {
          _id
          name
        }
      }
    }
  }
`;

export const INFLUENCER_COUNT = gql`
  query Q_Influencer_Count {
    Count {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        nowBookCnt
        accBookCnt
      }
    }
  }
`;

export const INFLUENCER_ACCOUNTINFO = gql`
  query Q_Inf_AccountFindByMe {
    AccountFindByMe {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        bankName
        accNumber
        bankOwnerName
      }
    }
  }
`;

export const INFLUENCER_REVENUE = gql`
  query Q_SettlementList($sort: [_SettlementSort!], $filter: _SettlementFilter, $pageInput: pageInput!) {
    SettlementList(sort: $sort, filter: $filter, pageInput: $pageInput) {
      ok
      error {
        location
        severity
        code
        message
      }
      page {
        page
        cntPerPage
        totalPageSize
        totalCount
      }
      data {
        status
        requestHistory {
          date
          reqType
        }
        bookings {
          _id
          status
          storeName
          bookingDate
          price
        }
        bookingsCnt
        startDate
        endDate
        totalPrice
        feePolicy {
          addtionalFees {
            feeName
            feePercent
            fee
          }
        }
        additionFeeSum
        settlePrice
      }
      supply {
        bookingPriceTotal
        feeTotal
        settlePriceTotal
      }
    }
  }
`;

/* :::::::::::::::::::::::::::::::::::::::::: 

    Query - Store 

::::::::::::::::::::::::::::::::::::::::: */

export const STORE_STORELIST = gql`
  query Q_StoreList($sort: [_StoreSort!], $filter: _StoreFilter, $pageInput: pageInput!) {
    StoreList(sort: $sort, filter: $filter, pageInput: $pageInput) {
      ok
      error {
        location
        severity
        code
        message
      }
      page {
        page
        cntPerPage
        totalPageSize
        totalCount
      }
      data {
        _id
        storeName
        address
        phoneNumber
        buisnessRange
        introduce
        naverSmartPlaceUrl
      }
    }
  }
`;

export const STORE_INFLUENCERLIST = gql`
  query Q_ProfileList($sort: [_ProfileSort!], $filter: _ProfileFilter, $pageInput: pageInput!) {
    ProfileList(sort: $sort, filter: $filter, pageInput: $pageInput) {
      ok
      error {
        location
        severity
        code
        message
      }
      page {
        page
        cntPerPage
        totalPageSize
        totalCount
      }
      data {
        _id
        isOpen
        name
        sns {
          ...Fsns
        }
        introduce
        unfavorList
        bookingTotalCnt
        categories {
          _id
          name
        }
        price
        profileImage {
          uri
          x
          y
        }
      }
    }
  }
  ${F_SNS}
`;

export const STORE_SPECIALLIST = gql`
  query Q_Store_SpecialList {
    RecommandProfileFindOne {
      ok
      error {
        location
        severity
        code
        message
      }

      data {
        _id
        isDisplay
        minCount
        maxCount
        profiles {
          _id
          name
          profileImage {
            uri
            x
            y
          }
          categories {
            _id
            name
          }
          homepageURL
          instagramURL
          introduce
          isOpen
          price
          unfavorList
          bookingTotalCnt
        }
      }
    }
  }
`;

export const STORE_PURCHASEINFO = gql`
  query Q_ProfileBuyInfo($profileId: String!) {
    ProfileBuyInfo(profileId: $profileId) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        profile {
          _id
          price
        }
        schedule {
          _id
          day
          self
          book
          monday {
            start
            end
          }
          tuesday {
            start
            end
          }
          wednesday {
            start
            end
          }
          thursday {
            start
            end
          }
          friday {
            start
            end
          }
          saturday {
            start
            end
          }
          sunday {
            start
            end
          }
        }
      }
    }
  }
`;

export const STORE_CART = gql`
  query Q_Store_BasketItemList($sort: [_BasketItemSort!], $filter: _BasketItemFilter, $pageInput: pageInput!) {
    BasketItemList(sort: $sort, filter: $filter, pageInput: $pageInput) {
      ok
      error {
        location
        severity
        code
        message
      }
      page {
        page
        cntPerPage
        totalPageSize
        totalCount
      }
      data {
        _id
        storeName
        bookingDate
        isBooking
        profile {
          name
          nickName
          bookingTotalCnt
          categories {
            _id
            name
          }
          profileImage {
            _id
            uri
            x
            y
          }
          instagramURL
          homepageURL
          unfavorList
          introduce
          price
        }
      }
    }
  }
`;

export const STORE_RECORDS = gql`
  query Q_Store_BookingList($sort: [_BookingSort!], $filter: _BookingFilter, $pageInput: pageInput!) {
    BookingList(sort: $sort, filter: $filter, pageInput: $pageInput) {
      ok
      error {
        location
        severity
        code
        message
      }
      page {
        page
        cntPerPage
        totalCount
      }
      data {
        _id
        createdAt
        storeName
        bookingDate
        price
        status
        profile {
          name
        }
      }
    }
  }
`;

/* ::::::::::::::::::::::::::::::::::::::::

    Query - Admin

:::::::::::::::::::::::::::::::::::::::: */

export const ADMIN_RECORDS = gql`
  query Q_Admin_BookingList($sort: [_BookingSort!], $filter: _BookingFilter, $pageInput: pageInput!) {
    BookingList(sort: $sort, filter: $filter, pageInput: $pageInput) {
      ok
      error {
        location
        severity
        code
        message
      }
      page {
        page
        cntPerPage
        totalCount
      }
      data {
        _id
        createdAt
        updatedAt
        bookingDate
        price
        bookerName
        storeName
        status
        isRejectReqCancel
        isPossibleBookingCancel
        profile {
          name
          instagramURL
        }
        schedule {
          _id
        }
        latestRequestHistory {
          reason
        }
      }
      supply {
        paymentPrice
        cancelPrice
      }
    }
  }
`;

export const ADMIN_STORELIST = gql`
  query Q_Admin_StoreList($sort: [_StoreSort!], $filter: _StoreFilter, $pageInput: pageInput!) {
    StoreList(sort: $sort, filter: $filter, pageInput: $pageInput) {
      ok
      error {
        location
        severity
        code
        message
      }
      page {
        page
        cntPerPage
        totalPageSize
        totalCount
      }
      data {
        _id
        storeName
        address
        phoneNumber
        buisnessRange
        introduce
        naverSmartPlaceUrl
      }
    }
  }
`;

export const ADMIN_USERLIST = gql`
  query Q_Admin_UserList($notLoginDay: Float, $sort: [_UserSort!], $filter: _UserFilter, $pageInput: pageInput!) {
    UserList(notLoginDay: $notLoginDay, sort: $sort, filter: $filter, pageInput: $pageInput) {
      ok
      error {
        location
        severity
        code
        message
      }
      page {
        page
        cntPerPage
        totalPageSize
        totalCount
      }
      data {
        _id
        lasteLogInDate
        email
        status
        profile {
          _id
          name
          profileImage {
            uri
            x
            y
          }
          categories {
            _id
            name
          }
          sns {
            ...Fsns
          }
          bookingTotalCnt
          homepageURL
          introduce
          isOpen
          price
          unfavorList
          phoneNumber
          region
          influencerCode
        }
      }
    }
  }
  ${F_SNS}
`;

export const ADMIN_SPECIALLISt = gql`
  query Q_Admin_SpecialList {
    RecommandProfileFindOne {
      ok
      error {
        location
        severity
        code
        message
      }

      data {
        _id
        isDisplay
        minCount
        maxCount
        profiles {
          _id
          name
          influencerEmail
          profileImage {
            uri
            x
            y
          }
          categories {
            _id
            name
          }
          homepageURL
          instagramURL
          introduce
          isOpen
          price
          unfavorList
        }
      }
    }
  }
`;

export const ADMIN_CANDIDATE = gql`
  query Q_Admin_ProfileList($sort: [_ProfileSort!], $filter: _ProfileFilter, $pageInput: pageInput!) {
    ProfileList(sort: $sort, filter: $filter, pageInput: $pageInput) {
      ok
      error {
        location
        severity
        code
        message
      }
      page {
        page
        cntPerPage
        totalPageSize
        totalCount
      }
      data {
        _id
        isOpen
        name
        instagramURL
        introduce
        bookingTotalCnt
        categories {
          _id
          name
        }
        price
        profileImage {
          uri
        }
      }
    }
  }
`;

export const ADMIN_SMSLIST = gql`
  query Q_Admin_SmsTemplateList($sort: [_ITemplateSort!], $filter: _ITemplateFilter, $pageInput: pageInput!) {
    SmsTemplateList(sort: $sort, filter: $filter, pageInput: $pageInput) {
      ok
      error {
        location
        severity
        code
        message
      }
      page {
        page
        cntPerPage
        totalPageSize
        totalCount
      }
      data {
        _id
        createdAt
        updatedAt
        name
        description
        content
        trigger {
          _id
          sender
          event
          isEnabled
          tags {
            key
            value
          }
        }
        replacers
        tags {
          key
          value
        }
      }
    }
  }
`;

export const ADMIN_REVENUE = gql`
  query Q_Admin_SettlementList($sort: [_SettlementSort!], $filter: _SettlementFilter, $pageInput: pageInput!) {
    SettlementList(sort: $sort, filter: $filter, pageInput: $pageInput) {
      ok
      error {
        location
        severity
        code
        message
      }
      page {
        page
        cntPerPage
        totalPageSize
        totalCount
      }
      data {
        _id
        status
        name
        phoneNumber
        influencerEmail
        bankName
        accNumber
        bankOwnerName
        requestHistory {
          date
          reqType
        }
        bookings {
          _id
          status
          storeName
          bookingDate
          price
        }
        bookingsCnt
        startDate
        endDate
        totalPrice
        feePolicy {
          addtionalFees {
            feeName
            feePercent
            fee
          }
        }
        additionFeeSum
        settlePrice
      }
      supply {
        bookingPriceTotal
        feeTotal
        settlePriceTotal
      }
    }
  }
`;

export const ADMIN_FEES = gql`
  query Q_Admin_FeePolicyFindOne {
    FeePolicyFindOne {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        status
        addtionalFees {
          feeName
          target
          type
          feePercent
          fee
        }
      }
    }
  }
`;

/* ::::::::::::::::::::::::::::::::::::::::

    Query - Super

:::::::::::::::::::::::::::::::::::::::: */

export const SUPER_EXTRAINFO = gql`
  query Q_SUPER_PageInfoList($sort: [_PageInfoSort!], $filter: _PageInfoFilter, $pageInput: pageInput!) {
    PageInfoList(sort: $sort, filter: $filter, pageInput: $pageInput) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        key
        value
      }
    }
  }
`;

/* ::::::::::::::::::::::::::::::::::::::::::::::::::::

Mutation - Common

:::::::::::::::::::::::::::::::::::::::::::::::::::: */

export const VERIFICATION_EMAIL = gql`
  mutation M_VerificationStart($target: VerificationTarget!, $payload: String!, $event: VerificationEvent!) {
    VerificationStart(target: $target, payload: $payload, event: $event) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        target
        payload
      }
    }
  }
`;

export const VERIFICATION_EMAIL_CHECK = gql`
  mutation M_VerificationComplete(
    $verificationId: ID!
    $target: VerificationTarget!
    $code: String!
    $payload: String!
  ) {
    VerificationComplete(verificationId: $verificationId, target: $target, code: $code, payload: $payload) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        target
        payload
      }
    }
  }
`;

export const SINGLE_UPDATE = gql`
  mutation SingleUpload($file: Upload!) {
    SingleUpload(file: $file) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        name
        description
        extension
        fileType
        uri
        owner
      }
    }
  }
`;

export const FINDPASSWORD = gql`
  mutation PasswordResetByMe($email: String!) {
    PasswordResetByMe(email: $email) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        resultObj
      }
    }
  }
`;

export const CHANGEPASSWORD = gql`
  mutation PasswordChangeByMe($currentPw: String!, $newPasswordConfirm: String!, $newPassword: String!) {
    PasswordChangeByMe(currentPw: $currentPw, newPasswordConfirm: $newPasswordConfirm, newPassword: $newPassword) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        name
      }
    }
  }
`;

export const ADMINCHANGEPASSWORD = gql`
  mutation PasswordChangeByAdmin($newPasswordConfirm: String!, $newPassword: String!, $id: String!) {
    PasswordChangeByAdmin(newPasswordConfirm: $newPasswordConfirm, newPassword: $newPassword, id: $id) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        name
        email
      }
    }
  }
`;

/* ::::::::::::::::::::::::::

    Mutation - Influencer

:::::::::::::::::::::::::: */

export const INFLUENCER_SIGNUP = gql`
  mutation SignUp($verificationId: String!, $params: SignUpInput!) {
    SignUp(verificationId: $verificationId, params: $params) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        email
      }
    }
  }
`;

export const INFLUENCER_BOOKING_REFUSE = gql`
  mutation M_BookingCancelReq($id: String!, $cancelReqReason: String!) {
    BookingCancelReq(id: $id, cancelReqReason: $cancelReqReason) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        status
        bookerName
        storeName
      }
    }
  }
`;

export const INFLUENCER_SCHEDULE_UPDATE = gql`
  mutation ScheduleUpdateByMe($params: ScheduleUpdateByMeInput!) {
    ScheduleUpdateByMe(params: $params) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        createdAt
        updatedAt
        isDelete
        influencer {
          _id
        }
        influencerEmail
        day
        self
        book
      }
    }
  }
`;

export const INFLUENCER_SCHEDULE_HOURS_UPDATE = gql`
  mutation ScheduleUpdateByMeHours($params: ScheduleUpdateByMeInput!) {
    ScheduleUpdateByMe(params: $params) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        influencer {
          _id
          name
        }
        monday {
          start
          end
        }
        tuesday {
          start
          end
        }
        wednesday {
          start
          end
        }
        thursday {
          start
          end
        }
        friday {
          start
          end
        }
        saturday {
          start
          end
        }
        sunday {
          start
          end
        }
      }
    }
  }
`;

export const INFLUENCER_PROFILE_UPDATE = gql`
  mutation ProfileUpdateByMe($params: ProfileUpdateByMeInput!) {
    ProfileUpdateByMe(params: $params) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        profileImage {
          _id
          name
          description
          extension
          fileType
          uri
          owner
          x
          y
        }
        categories {
          name
        }
        homepageURL
        sns {
          label
          value
        }
        introduce
        isOpen
      }
    }
  }
`;

export const INFLUENCER_PROFILE_BANKINFO_UPDATE = gql`
  mutation M_AccountUpdateByMe($params: AccountUpdateByMeInput!) {
    AccountUpdateByMe(params: $params) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        bankName
        accNumber
        bankOwnerName
      }
    }
  }
`;

/* ::::::::::::::::::::::::::::::::::::::::

    Mutation - Store

:::::::::::::::::::::::::::::::::::::::: */

export const STORE_SIGNIN = gql`
  mutation BusinessSignIn($params: BusinessSignInArgs!) {
    BusinessSignIn(params: $params) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        token
        verificationId
      }
    }
  }
`;

export const STORE_SIGNIN_UPDATE = gql`
  mutation BusinessSignInUpdate($params: BusinessSignInUpdateArgs!, $verificationId: String!) {
    BusinessSignInUpdate(params: $params, verificationId: $verificationId) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        token
      }
    }
  }
`;

export const STORE_STORECREATE = gql`
  mutation M_StoreCreate($params: StoreCreateInput!) {
    StoreCreate(params: $params) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        storeName
      }
    }
  }
`;

export const STORE_STOREUPDATE = gql`
  mutation M_StoreUpdate($params: StoreUpdateInput!, $id: String!) {
    StoreUpdate(params: $params, id: $id) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        storeName
        address
        phoneNumber
      }
    }
  }
`;

export const STORE_STOREDELETE = gql`
  mutation M_StoreDelete($id: String!) {
    StoreDelete(id: $id) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        storeName
        address
        phoneNumber
      }
    }
  }
`;

export const STORE_ADDCART = gql`
  mutation M_BasketItemCreate($params: BasketItemCreateInput!) {
    BasketItemCreate(params: $params) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        price
        bookerMemo
      }
    }
  }
`;

export const STORE_DELETECART = gql`
  mutation M_BasketItemDelete($id: String!) {
    BasketItemDelete(id: $id) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
      }
    }
  }
`;

export const STORE_PURCHASECART = gql`
  mutation M_ReadyQueItemCreateForBasket($basketIds: [String!]!) {
    ReadyQueItemCreateForBasket(basketIds: $basketIds) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        groupCode
        bookerEmail
        bookerPhoneNumber
        bookerName
      }
    }
  }
`;

export const STORE_PURCHASE = gql`
  mutation M_ReadyQueItemCreateForProfile($params: ReadyQueItemCreateForProfileInput!) {
    ReadyQueItemCreateForProfile(params: $params) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        groupCode
        bookerEmail
        bookerPhoneNumber
        bookerName
      }
    }
  }
`;

/* ::::::::::::::::::::::::::::::::::::::::

    Mutation - Admin

:::::::::::::::::::::::::::::::::::::::: */

export const ADMIN_BOOKING_CANCEL = gql`
  mutation M_BookingCancel($params: BookingCancelInput!) {
    BookingCancel(params: $params) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        profile {
          name
        }
        bookingDate
      }
    }
  }
`;

export const ADMIN_BOOKING_CANCEL_REJECT = gql`
  mutation M_BookingCancelReqWithdraw($id: String!) {
    BookingCancelReqWithdraw(id: $id) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        bookingDate
        bookerName
      }
    }
  }
`;

export const ADMIN_USER_PRICEUPDATE = gql`
  mutation M_Admin_PriceUpdate($priceId: String!, $profileId: String!) {
    ProfileUpdateForSetPrice(priceId: $priceId, profileId: $profileId) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        price
      }
    }
  }
`;

export const ADMIN_USER_START = gql`
  mutation M_UserStart($id: String!) {
    UserStart(id: $id) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        name
        rejectReason
        rejectDate
      }
    }
  }
`;

export const ADMIN_USER_STOP = gql`
  mutation M_UserStop($id: String!, $reason: String!) {
    UserStop(id: $id, reason: $reason) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        name
        rejectReason
        rejectDate
      }
    }
  }
`;

export const ADMIN_USER_DELETE = gql`
  mutation M_UserDelete($id: String!, $reason: String!) {
    UserDelete(id: $id, reason: $reason) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        name
        rejectReason
        rejectDate
      }
    }
  }
`;

export const ADMIN_USER_DELETECANCEL = gql`
  mutation M_UserRecoveryFromDelete($id: String!) {
    UserRecoveryFromDelete(id: $id) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
      }
    }
  }
`;

export const ADMIN_SPECIALLIST_UPDATE = gql`
  mutation M_SpecialList_Update($params: RecommandProfileUpdateInput!) {
    RecommandProfileUpdate(params: $params) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        profiles {
          _id
          name
        }
      }
    }
  }
`;

export const ADMIN_CATEGORY_CREATE = gql`
  mutation M_CategoryCreate($params: CategoryCreateInput!) {
    CategoryCreate(params: $params) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        name
      }
    }
  }
`;

export const ADMIN_CATEGORY_UPDATE = gql`
  mutation M_CategoryUpdate($id: String!, $params: CategoryUpdateInput!) {
    CategoryUpdate(id: $id, params: $params) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        name
      }
    }
  }
`;

export const ADMIN_CATEGORY_DELETE = gql`
  mutation M_CategoryDelete($id: String!) {
    CategoryDelete(id: $id) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        name
      }
    }
  }
`;

export const ADMIN_PRICE_CREATE = gql`
  mutation M_PriceCreate($price: Float!) {
    PriceCreate(price: $price) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        price
      }
    }
  }
`;

export const ADMIN_PRICE_UPDATE = gql`
  mutation M_PriceUpdate($id: String!, $price: Float!) {
    PriceUpdate(id: $id, price: $price) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        price
      }
    }
  }
`;

export const ADMIN_PRICE_DELETE = gql`
  mutation M_PriceDelete($altPriceId: String!, $delTargetId: String!) {
    PriceDelete(altPriceId: $altPriceId, delTargetId: $delTargetId) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        price
      }
    }
  }
`;

export const ADMIN_CANDIDATE_ACCEPT = gql`
  mutation M_SignUpAccept($id: String!) {
    SignUpAccept(id: $id) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        name
        email
      }
    }
  }
`;

export const ADMIN_CANDIDATE_REJECT = gql`
  mutation M_SignUpReject($id: String!, $rejectReason: String!) {
    SignUpReject(id: $id, rejectReason: $rejectReason) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        name
        email
      }
    }
  }
`;

export const ADMIN_CANDIDATE_PRICESET = gql`
  mutation M_ProfileUpdateForSetPrice($priceId: String!, $profileId: String!) {
    ProfileUpdateForSetPrice(priceId: $priceId, profileId: $profileId) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        name
        price
      }
    }
  }
`;

export const ADMIN_SMSUPDATE = gql`
  mutation M_SmsTemplateUpdate($input: SmsTemplateUpdateInput!, $templateId: String!) {
    SmsTemplateUpdate(input: $input, templateId: $templateId) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        content
      }
    }
  }
`;

export const ADMIN_REVENUECOMPLETE = gql`
  mutation M_SettlementComplete($id: String!) {
    SettlementComplete(id: $id) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        status
        name
      }
    }
  }
`;

export const ADMIN_REVENUECANCEL = gql`
  mutation M_SettlementCancel($id: String!) {
    SettlementCancel(id: $id) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        status
        name
      }
    }
  }
`;

export const ADMIN_FEESUPDATE = gql`
  mutation M_FeePolicyUpdate($params: FeePolicyUpdateInput!) {
    FeePolicyUpdate(params: $params) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        status
      }
    }
  }
`;

/* ::::::::::::::::::::::::::::::::::::::::

    Query - Super

:::::::::::::::::::::::::::::::::::::::: */

export const SUPER_EXTRAINFOCREATE = gql`
  mutation M_SUPER_PageInfoCreate($params: PageInfoCreateInput!) {
    PageInfoCreate(params: $params) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        key
        value
      }
    }
  }
`;

export const SUPER_EXTRAINFOUPDATE = gql`
  mutation M_SUPER_PageInfoUpdate($key: String!, $params: PageInfoUpdateInput!) {
    PageInfoUpdate(key: $key, params: $params) {
      ok
      error {
        location
        severity
        code
        message
      }
      data {
        _id
        key
        value
      }
    }
  }
`;
