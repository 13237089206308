import React, { useEffect, useState } from "react";
import { ModalContext, ContextExtraData } from "context/ContextInfluencer";
import { useQuery } from "@apollo/client";
import { Q_SUPER_PageInfoList, _PageInfoSort } from "apollo/types";
import { SUPER_EXTRAINFO } from "apollo/gql/query";
import "styles/admin/admin.scss";
import { token_role } from "utils/commonVariables";
import Sidebar from "component/common/sidebar/Sidebar";
import GraphError from "component/common/error/GraphError";
import { ExtraDataProcess } from "utils/utils";
import { TextraDataValue } from "type/super/superTypes";
import { TModalState, TToken_Role } from "type/types";
import RoleAuthError from "component/common/error/RoleAuthError";

console.log("Version : 1.1");

const Admin: React.FC = ({ children }: any) => {
  const [commonValue, setCommonValue] = useState<any>({
    role: "",
    isLogged: false,
  });

  const [modalState, setModalState] = useState<TModalState>({
    modal: "",
    active: false,
  });

  /* ::::: API CALL - 여분필드 리스트 ::::: */
  const {
    loading: exraLoading,
    error: extraError,
    data: extraData,
  } = useQuery<Q_SUPER_PageInfoList>(SUPER_EXTRAINFO, {
    variables: {
      sort: [_PageInfoSort.createdAt_desc],
      pageInput: {
        page: 1,
        cntPerPage: 10,
      },
    },
    fetchPolicy: "network-only",
  });

  const menuList = [
    { name: "주문내역", link: "/admin/orders" },
    { name: "거절요청 리스트", link: "/admin/reject_request" },
    { name: "환불내역", link: "/admin/refunds" },
    // {name:"거절승인 내역", link:"/admin/reject_history"},
    { name: "스토어 리스트", link: "/admin/storelist" },
    { name: "인플루언서 리스트", link: "/admin/influencer" },
    { name: "추천 인플루언서 관리", link: "/admin/speciallist" },
    { name: "인플루언서 신청현황", link: "/admin/candidates" },
    { name: "카테고리 관리", link: "/admin/category" },
    { name: "SMS 관리", link: "/admin/sms" },
    { name: "정산관리", link: "/admin/revenue" },
    { name: "수수료설정", link: "/admin/feesetting" },
  ];

  if (localStorage.getItem(token_role) !== TToken_Role.super) {
    if (localStorage.getItem(token_role) !== TToken_Role.admin) {
      return <RoleAuthError role={localStorage.getItem(token_role)!} />;
    }
  }
  if (exraLoading) return null;
  if (extraError) return <GraphError err={extraError.message} page="admin" />;
  const extraDataValue: TextraDataValue = ExtraDataProcess(extraData?.PageInfoList.data);

  // console.log('admin common');
  // console.log(localStorage.getItem(token_role));

  return (
    <ModalContext.Provider value={{ modalState, setModalState }}>
      <ContextExtraData.Provider value={{ extraDataValue }}>
        <div className={`admin`}>
          <div className={`bgfilter ${modalState.active && "on"}`}></div>
          <Sidebar title={"관리자"} menuList={menuList} extraDataValue={extraDataValue} />
          {children}
        </div>
      </ContextExtraData.Provider>
    </ModalContext.Provider>
  );
};

export default Admin;
