import { gql } from "@apollo/client";

export const F_SNS = gql`
  fragment Fsns on SNS {
    label
    value
  }
`;

export const F_FILE = gql`
  fragment Ffile on File {
    name
    description
    extension
    fileType
    uri
    owner
    x
    y
  }
`;

export const F_CATEGORY = gql`
  fragment Fcategory on Category {
    name
  }
`;

export const F_PROFILE = gql`
  fragment FProfile on Profile {
    _id
    createdAt
    updatedAt
    isDelete
    influencerEmail
    name
    nickName
    profileImage {
      ...Ffile
    }
    categories {
      ...Fcategory
    }
    homepageURL
    instagramURL
    introduce
    isOpen
    price
    sns {
      ...Fsns
    }
    influencerCode
  }

  ${F_SNS}
  ${F_FILE}
  ${F_CATEGORY}
`;

export const F_USER = gql`
  fragment Fuser on User {
    _id
    createdAt
    updatedAt
    isDelete
    email
    status
    role
    phoneNumber
    nickName
    name
    profile {
      ...FProfile
    }
    lasteLogInDate
    isVerifiedPhoneNumber
    isVerifiedEmail
    isVerifiedManager
    acceptSms
  }
  ${F_PROFILE}
`;

export const F_STORE = gql`
  fragment FStore on Store {
    sosang_gong_in {
      ...Fuser
    }
    buisnessRange
    storeName
    address
    phoneNumber
    introduce
    naverSmartPlaceUrl
  }

  ${F_USER}
`;

export const F_TIME = gql`
  fragment Ftime on Time {
    start
    end
  }
`;

export const F_SCHEDULE = gql`
  fragment FSchedule on Schedule {
    _id
    createdAt
    updatedAt
    isDelete
    influencer {
      ...Fuser
    }
    influencerEmail
    profile {
      ...FProfile
    }
    day
    self
    book
    monday {
      ...Ftime
    }
    tuesday {
      ...Ftime
    }
    wednesday {
      ...Ftime
    }
    thursday {
      ...Ftime
    }
    friday {
      ...Ftime
    }
    saturday {
      ...Ftime
    }
    sunday {
      ...Ftime
    }
  }
  ${F_PROFILE}
  ${F_USER}
  ${F_TIME}
`;

// @Jihoon
// 20220228 현재 사용되진 않지만 알림 타입을 사용하기 시작하면
// 타입을 정의 해줘야할듯 함
// export const F_REQUESTHISTORY = gql`
//   fragment FRequestHistory on RequestHistory {
//     methodType
//     reqType
//     date
//     reason
//   }
// `;

// export const F_NOTIFICATIONMANAGER = gql`
//   fragment FNotificationManager on NotificationManager {
//     senders
//     smsPricingTable
//     emailPricing
//     currency
//     pointRemains
//     templates
//   }
// `;
