import React from 'react'
interface IProp {
    text:string
}

const PageLoading:React.FC<IProp> = ({text}) => {
    return (
        <div className="loading">
           <div>
                <div className="loading__circle"></div>
                <div className="loading__text">{text}</div>
           </div>
        </div>
    )
}

export default PageLoading
