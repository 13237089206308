import React from 'react'
import { Link } from 'react-router-dom'
import { TToken_Role } from 'type/types';

interface IProps{
    role:string
}

const RoleAuthError:React.FC<IProps> = ({role}) => {
    
    let redirect_url = '/login/select';

    if(role === TToken_Role.influencer) {
        redirect_url = "/login/select"
    }
    if(role === TToken_Role.business) {
        redirect_url = "/login/select"
    }
    if(role === TToken_Role.admin) {
        redirect_url = "/login/select"
    }

    return (
       <div className="error">
           <div className="error__wrap">
               <h1 className="error__title">해당 페이지에 접근할 권한이 없습니다</h1>
               <div className="error__btnwrap">
                    <Link to={redirect_url} className="error__btn">
                        돌아가기
                    </Link>
               </div>
           </div>
       </div>
    )
}

export default RoleAuthError
